import React from 'react';
import s from './style.module.scss';
import { TitleText, Question_section, Footer } from '../../component/index';
import Section_main from './Section_main';
import Section_preferens from './Section_preferens';
import Section_product from './Section_product';
import Section_blog from './Section_blog';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../constans/animation";


const Index = React.memo(function Index(props) {

    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Section_main />
            <Section_preferens />
            <Section_product />
            <Section_blog />
            <Question_section />
            <Footer />
        </motion.div>
    )
});

export default Index;