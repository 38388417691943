
export const fetch_text = ({ values, loading, infoMassege }) => {
    loading(true);
    const message_accept = ['Дані успішно змінено!', 'success'];
    const message_warning = ['Після завершення редагування, перезапустіть сторінку для оновлення даних', 'warning'];
    const message_bad = ['Ой, трапилася помилка! Можливо ви ввели некоректні дані або спробуйте пізніше.', 'error'];

    // const dispatch = useDispatch();
    fetch('/api/text-edit/', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            setTimeout(() => {
                let error = data.detail;

                if (error !== undefined) {
                    infoMassege(message_bad);
                } else {
                    infoMassege(message_accept);
                    infoMassege(message_warning);
                }
                loading(false);
            }, 1500);
        });
};