import React from 'react';
import s from './style.module.scss';
import section_bg from '../../assets/index/question_bg.png';
import { TitleText, CustomBtn } from '../index';
import Consultation_btn from '../Consultation_btn';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import Contact_btn from '../Contact_btn';



const Question_section = React.memo(function Question_section(props) {
    const [
        text44 = [], text45 = [],
        text46 = [], text47 = [], text145 = [],
    ] = useSelector(({ text }) => [
        text.json[44], text.json[45],
        text.json[46], text.json[47], text.json[145],
    ]);
    return (
        <>
            <section className={`${s.section} container`}>
                <LazyLoad className={s.section_bg}>
                    <img src={section_bg} alt="section background" />
                </LazyLoad>
                <div className={s.section_content}>
                    <TitleText editable={text44[1]} style={s.section_title} animation={true} tag='div' weight='700' size='3' color='white'>
                        {/* Залишились запитаня? */}
                        {text44[0]}
                    </TitleText>
                    <TitleText editable={text45[1]} style={s.section_text} tag='div' weight='700' size='5' color='white'>
                        {/* Для детальної інформації звя’жіться з нашими консультантами */}
                        {text45[0]}
                    </TitleText>
                </div>
                <div className={s.section_btn__block}>
                    <Consultation_btn text46={text46} />
                    <CustomBtn style={s.section_btn_phone} tag='a' color='white' href='tel:380972416037'>
                        <TitleText editable={text47[1]} tag='span' weight='700' size='10' color='white'>
                            {/* Зателефонувати */}
                            {text47[0]}
                        </TitleText>
                    </CustomBtn>
                    <Contact_btn text145={text145} />
                </div>
            </section>
        </>
    )
});

export default Question_section;
