import React from 'react';
import s from './style.module.scss';
import './fix_gallery.css';
import gallery_bg from '../../assets/gallery/gallery_bg.png';
import { Start_section, Question_section, Footer, Loader } from '../../component';
import Gallery from "react-photo-gallery";
import { return_photos } from "../../api/text/gallery";
import { motion, } from 'framer-motion';
import { pageTransition } from "../../constans/animation";
import { useSelector } from 'react-redux';
import generate_gallery from './generateGalerry';

const Gallery_page = React.memo(function Gallery_page(props) {
    const [photos, Set_photos] = React.useState();
    const [gallery, Set_gallery] = React.useState();
    React.useEffect(() => {
        return_photos(Set_photos, '/api/galary/');
    }, []);

    const [
        text3 = []
    ] = useSelector(({ text }) => [
        text.json[3]
    ]);

    React.useEffect(() => {
        if (photos != undefined) {
            // повертає масив з розмірами і силкою на фото, 
            // якщо повільний інтернет, то вертає фіксоване відношення сторін
            generate_gallery(photos, Set_gallery);
        }
    }, [photos]);

    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text3} bg={gallery_bg} />
            <section className={`${s.section} container`}>
                {gallery &&
                    <Gallery photos={gallery} />
                }
            </section>
            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default Gallery_page;
