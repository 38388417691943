import React from 'react'
import s from './style.module.scss';
import loader_gif from '../../assets/img/loader.gif';

function Loader() {
    return (
        <section className={s.section}>
            <img src={loader_gif} alt="loader" />
        </section>
    )
}

export default Loader
