import React from 'react';
import s from '../style.module.scss';
import garden_bg from '../../../assets/categories/garden_bg.png';
import { Start_section, TitleText, Custom_slider, Custom_option, Order_btn, Question_section, Footer } from '../../../component/index';
import Section_info from '../Section_info';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../../constans/animation";
import { useSelector } from 'react-redux';
import { return_photos } from '../../../api/text/gallery';

const Garden = React.memo(function Garden(props) {
    const [photos_garden, Set_photos_garden] = React.useState();

    React.useEffect(() => {
        if (photos_garden == undefined) {
            return_photos(Set_photos_garden, '/api/galary-garden/');
        }
    }, []);


    const [
        text59 = [], text190 = [], text231 = [],
    ] = useSelector(({ text }) => [
        text.json[59], text.json[190], text.json[231],
    ]);

    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text59} bg={garden_bg} />
            <div className={`${s.section} container`}>
                <div className={s.section_content__block}>
                    <div className={s.section_content}>
                        <TitleText editable={text190[1]} style={`${s.section_content_title}`} tag='div' weight='700' size='5' color='black'>
                            {/* Результати */}
                            {text190[0]}
                        </TitleText>

                        <div className={s.section_content_img}>
                            <iframe src="https://www.youtube.com/embed/qYZVKA-j_ww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <Section_info />
            </div>
            <div className={`${s.section_slider} container`}>
                <TitleText editable={text231[1]} style={`${s.section_slider_title}`} tag='div' weight='700' size='5' color='black'>
                    {/* Галерея */}
                    {text231[0]}
                </TitleText>
                {photos_garden != undefined && photos_garden.length != 0 &&
                    <Custom_slider slider='first' slides={photos_garden} />
                }
            </div>
            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default Garden;
