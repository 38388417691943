import React from 'react';
import s from './style.module.scss';
import section_bg from '../../assets/index/question_bg.png';
import { TitleText } from '../index';
import { Link } from 'react-router-dom';
import { publicRoutes } from '../../constans/routes';
import { Footer_logo } from '../../assets/svg/Logo';
import { additional_item } from '../../api/text/additional_item';
import StarWay from './StarWay';
import { useSelector } from 'react-redux';


const Footer = React.memo(function Footer(props) {


    const [
        text1 = [], text2 = [], text3 = [], text52 = [], text54 = [],
        text55 = [], text48 = [], text49 = [], text56 = [], text57 = [],
        text58 = [], text59 = [], text60 = [], text61 = [], text62 = [],
    ] = useSelector(({ text }) => [
        text.json[1], text.json[2], text.json[3], text.json[52], text.json[54],
        text.json[55], text.json[48], text.json[49], text.json[56], text.json[57],
        text.json[58], text.json[59], text.json[60], text.json[61], text.json[62],
    ]);

    let additional_items = [{
        id: text56[1],
        name: text56[0],
        path: publicRoutes.CORN,
    },
    {
        id: text57[1],
        name: text57[0],
        path: publicRoutes.CROPS,
    },
    {
        id: text58[1],
        name: text58[0],
        path: publicRoutes.BEANS,
    },
    {
        id: text59[1],
        name: text59[0],
        path: publicRoutes.GARDEN,
    },
    {
        id: text60[1],
        name: text60[0],
        path: publicRoutes.VEGETABLES,
    },
    {
        id: text61[1],
        name: text61[0],
        path: publicRoutes.NOTILL,
    },
    {
        id: text62[1],
        name: text62[0],
        path: publicRoutes.AGRICULTURE,
    },];

    return (
        <>
            <section className={`${s.section} container`}>
                <div className={s.section__block}>
                    <div className={s.section_img}>
                        <Link to={publicRoutes.INDEX} className={s.section_logo} >
                            <Footer_logo />
                        </Link>
                        <TitleText editable={text48[1]} style={s.section_img_text} tag='div' weight='400' size='10' color='white'>
                            {/* © 2021. Агрогров. Всі права захищено */}
                            {text48[0]}
                        </TitleText>
                    </div>

                    <div className={s.section_content}>
                        <TitleText editable={text49[1]} style={s.section_title} tag='div' weight='700' size='9' color='white'>
                            {/* Інфо */}
                            {text49[0]}
                        </TitleText>
                        <Link to={publicRoutes.ABOUT} className={s.section_nav_link} >
                            <TitleText editable={text1[1]} tag='span' weight='400' size='10' color='white'>
                                {/* Про нас */}
                                {text1[0]}
                            </TitleText>
                        </Link>
                        <Link to={publicRoutes.MYCORRHIZA} className={s.section_nav_link} >
                            <TitleText editable={text2[1]} tag='span' weight='400' size='10' color='white'>
                                {/* Мікориза ВеллВам */}
                                {text2[0]}
                            </TitleText>
                        </Link>

                        <Link to={publicRoutes.GALLERY} className={s.section_nav_link} >
                            <TitleText editable={text3[1]} tag='span' weight='400' size='10' color='white'>
                                {/* Галерея */}
                                {text3[0]}
                            </TitleText>
                        </Link>
                        <Link to={publicRoutes.BLOG} className={s.section_nav_link} >
                            <TitleText editable={text52[1]} tag='span' weight='400' size='10' color='white'>
                                {/* Статті */}
                                {text52[0]}
                            </TitleText>
                        </Link>
                        <a className={s.section_nav_link} href="https://tviilis.org/" target="_blank">
                            <TitleText editable={text54[1]} tag='span' weight='400' size='10' color='white'>
                                {/* Проєкт ГО ТВІЙ ЛІС */}
                                {text54[0]}
                            </TitleText>
                        </a>
                    </div>
                    <div className={`${s.section_content}`}>
                        <TitleText editable={text55[1]} style={s.section_title} tag='div' weight='700' size='9' color='white'>
                            {/* Культури і технології */}
                            {text55[0]}
                        </TitleText>
                        {additional_items &&
                            additional_items.map((item, index) => {
                                return (
                                    <Link to={item.path} className={s.section_nav_link} key={`${item.id}_${index}`} >
                                        <TitleText editable={item.id} tag='span' weight='400' size='10' color='white'>
                                            {item.name}
                                        </TitleText>
                                    </Link>
                                )
                            })}
                    </div>
                </div>
                <StarWay />
            </section>
        </>
    )
});

export default Footer;
