import React from 'react'
import { TitleText, Custom_option } from '../../../component';
import { preferens_items, usage_items, unusage_items, return_pdf_files } from '../../../api/text/mycorrhiza';
import download_img from '../../../assets/index/path-arrow.svg';
import plus from '../../../assets/mycorrhiza/plus.svg';
import minus from '../../../assets/mycorrhiza/minus.svg';
import s from './style.module.scss';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';

function Section_preferens(props) {
    const [pdf_files, Set_pdf_files] = React.useState();
    const [
        text85 = [], text94 = [], text95 = [], text96 = [], text97 = [],
        text98 = [], text99 = [], text100 = [], text101 = [], text102 = [],
        text103 = [], text104 = [], text105 = [], text106 = [], text107 = [],
        text108 = [], text109 = [], text110 = [], text111 = [], text112 = [],
        text113 = [], text114 = [], text115 = [], text116 = [], text117 = [],
        text118 = [], text119 = [], text120 = [], text121 = [], text122 = [],
        text123 = [], text124 = [], text125 = [], text126 = [], text127 = [],

        text128 = []
    ] = useSelector(({ text }) => [
        text.json[85], text.json[94], text.json[95], text.json[96], text.json[97],
        text.json[98], text.json[99], text.json[100], text.json[101], text.json[102],
        text.json[103], text.json[104], text.json[105], text.json[106], text.json[107],
        text.json[107], text.json[109], text.json[110], text.json[111], text.json[112],
        text.json[113], text.json[114], text.json[115], text.json[116], text.json[117],
        text.json[118], text.json[119], text.json[120], text.json[121], text.json[122],
        text.json[123], text.json[124], text.json[125], text.json[126], text.json[127],
        text.json[128]
    ]);

    let preferens_items = [{
        alt: 'preferens img',
        src: require(`../../../assets/mycorrhiza/pref_1.svg`).default,
        title: props.text86,
        text: props.text87
    },
    {
        alt: 'preferens img',
        src: require(`../../../assets/mycorrhiza/pref_2.svg`).default,
        title: props.text88,
        text: props.text89
    },
    {
        alt: 'preferens img',
        src: require(`../../../assets/mycorrhiza/pref_3.svg`).default,
        title: props.text90,
        text: props.text91
    },
    {
        alt: 'preferens img',
        src: require(`../../../assets/mycorrhiza/pref_4.svg`).default,
        title: props.text92,
        text: props.text93
    },];

    React.useEffect(() => {
        return_pdf_files(Set_pdf_files);
    }, []);

    return (
        <section className={`${s.section} container`}>
            <TitleText editable={text85[1]} style={`${s.section_title}`} tag='div' weight='700' size='5' color='black'>
                {/* Переваги “Веллвам” */}
                {text85[0]}
            </TitleText>

            <div className={s.section__block}>
                {preferens_items &&
                    preferens_items.map((item, index) => {
                        return (
                            <div className={s.section_profile} key={`${index}_${item.alt}`}>
                                <LazyLoad>
                                    <img src={item.src} alt={item.alt} />
                                </LazyLoad>
                                <TitleText editable={item.title[1]} style={`${s.section_profile_title}`} tag='div' weight='700' size='9' color='black'>
                                    {item.title[0]}
                                </TitleText>
                                <TitleText editable={item.title[1]} style={`${s.section_profile_text}`} tag='div' weight='400' size='10' color='black'>
                                    {item.text[0]}
                                </TitleText>
                            </div>
                        )
                    })
                }

            </div>

            <div className={s.info__block}>
                {pdf_files &&
                    pdf_files.map((item, index) => {
                        return (
                            <a href={item.file_url} download key={index + item.id} className={`${s.download_item} ${s.hvr_wobble_skew}`}>
                                <TitleText editable={text94[1]} style={`${s.download_item_text}`} tag='div' weight='700' size='5' color='black'>
                                    {/* Свідоцтво про державну реєстрацію */}
                                    {text94[0]}
                                </TitleText>
                                <img src={download_img} alt="download" />
                            </a>
                        )
                    })
                }

                <div className={s.info_content}>
                    <TitleText editable={text95[1]} style={`${s.info_content_text}`} tag='div' weight='700' size='5' color='black'>
                        {/* Вплив хімічних фунгіцидів на «ВеллВАМ» */}
                        {text95[0]}
                    </TitleText>
                    <div className={s.info_content_profile}>
                        <TitleText editable={text96[1]} style={`${s.info_content_profile_title}`} tag='div' weight='700' size='9' color='green'>
                            {/* Протруйники */}
                            {text96[0]}
                        </TitleText>
                        <TitleText editable={text97[1]} style={`${s.info_content_profile_text}`} tag='div' weight='400' size='9' color='black'>
                            {/* Фунгіциди для обробки насіння, як правило, безпечні внаслідок низьких використовуваних норм */}
                            {text97[0]}
                        </TitleText>
                    </div>
                    <div className={s.info_content_profile}>
                        <TitleText editable={text98[1]} style={`${s.info_content_profile_title}`} tag='div' weight='700' size='9' color='green'>
                            {/* Листові */}
                            {text98[0]}
                        </TitleText>
                        <TitleText editable={text99[1]} style={`${s.info_content_profile_text}`} tag='div' weight='400' size='9' color='black'>
                            {/* Листові несистемні фунгіциди використовуйте в любий час */}
                            {text99[0]}
                        </TitleText>
                    </div>
                    <div className={s.info_content_profile}>
                        <TitleText editable={text100[1]} style={`${s.info_content_profile_title}`} tag='div' weight='700' size='9' color='green'>
                            {/* Грунтові */}
                            {text100[0]}
                        </TitleText>
                        <TitleText editable={text101[1]} style={`${s.info_content_profile_text}`} tag='div' weight='400' size='9' color='black'>
                            {/* Уникайте ґрунтових фунгіцидів за два тижні до інокуляції (довше, якщо системна) і через чотири тижні після внесення */}
                            {text101[0]}
                        </TitleText>
                    </div>
                </div>
            </div>

            <div className={s.usage__wrap}>
                <TitleText editable={text102[1]} style={`${s.usage_title}`} tag='div' weight='700' size='5' color='black'>
                    {/* Фунгіциди, дозволені та заборонені для спільного використання */}
                    {text102[0]}
                </TitleText>
                <div className={s.usage__block}>
                    <div className={`${s.usage_profile} ${s.usage_profile_1}`}>
                        <TitleText editable={text103[1]} style={`${s.usage_profile_title}`} tag='div' weight='700' size='9' color='green'>
                            {/* Дозволені */}
                            {text103[0]}
                        </TitleText>
                        <div className={s.usage_item__block}>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text104[1]} tag='span' weight='400' size='9' color='black'>
                                    {text104[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text105[1]} tag='span' weight='400' size='9' color='black'>
                                    {text105[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text106[1]} tag='span' weight='400' size='9' color='black'>
                                    {text106[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text107[1]} tag='span' weight='400' size='9' color='black'>
                                    {text107[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text108[1]} tag='span' weight='400' size='9' color='black'>
                                    {text108[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text109[1]} tag='span' weight='400' size='9' color='black'>
                                    {text109[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text110[1]} tag='span' weight='400' size='9' color='black'>
                                    {text110[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text111[1]} tag='span' weight='400' size='9' color='black'>
                                    {text111[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text112[1]} tag='span' weight='400' size='9' color='black'>
                                    {text112[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text113[1]} tag='span' weight='400' size='9' color='black'>
                                    {text113[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text114[1]} tag='span' weight='400' size='9' color='black'>
                                    {text114[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text115[1]} tag='span' weight='400' size='9' color='black'>
                                    {text115[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text116[1]} tag='span' weight='400' size='9' color='black'>
                                    {text116[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text117[1]} tag='span' weight='400' size='9' color='black'>
                                    {text117[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text118[1]} tag='span' weight='400' size='9' color='black'>
                                    {text118[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={plus} >
                                <TitleText editable={text119[1]} tag='span' weight='400' size='9' color='black'>
                                    {text119[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                    </div>
                    <div className={`${s.usage_profile} ${s.usage_profile_2}`}>
                        <TitleText editable={text120[1]} style={`${s.usage_profile_title}`} tag='div' weight='700' size='9' color='red'>
                            {/* Заборонені */}
                            {text120[0]}
                        </TitleText>
                        <div className={s.usage_item__block}>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text121[1]} tag='span' weight='400' size='9' color='black'>
                                    {text121[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text122[1]} tag='span' weight='400' size='9' color='black'>
                                    {text122[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text123[1]} tag='span' weight='400' size='9' color='black'>
                                    {text123[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text124[1]} tag='span' weight='400' size='9' color='black'>
                                    {text124[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text125[1]} tag='span' weight='400' size='9' color='black'>
                                    {text125[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text126[1]} tag='span' weight='400' size='9' color='black'>
                                    {text126[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.usage_item} svg={minus} >
                                <TitleText editable={text128[1]} tag='span' weight='400' size='9' color='black'>
                                    {text128[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_preferens;
