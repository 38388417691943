import React from 'react'
import { TitleText, Custom_option } from '../../../component';
import s from './style.module.scss';
import Custom_select from '../Custom_select';
import { qustion_items } from '../../../api/text/mycorrhiza';
import { useSelector } from 'react-redux';


function Section_question() {
    const [
        text129 = [], text130 = [], text131 = [],
        text132 = [], text133 = [], text134 = [],
        text135 = [], text136 = [], text137 = [],
        text138 = [], text139 = [], text140 = [],
        text141 = [], text142 = [], text143 = [],
        text144 = [], text145 = [], text146 = [],
        text147 = [], text148 = [], text149 = [],
        text150 = [], text151 = [], text152 = [],
    ] = useSelector(({ text }) => [
        text.json[129], text.json[130], text.json[131],
        text.json[132], text.json[133], text.json[134],
        text.json[135], text.json[136], text.json[137],
        text.json[138], text.json[139], text.json[140],
        text.json[141], text.json[142], text.json[143],
        text.json[144], text.json[145], text.json[146],
        text.json[147], text.json[148], text.json[149],
        text.json[150], text.json[151], text.json[152],
    ]);

    return (
        <section className={`${s.section} container`}>
            <TitleText editable={text129[1]} style={`${s.section_title}`} tag='div' weight='700' size='5' color='white'>
                {/* Везикулярно-арбускулярна мікориза */}
                {text129[0]}
            </TitleText>
            <div className={s.select__block}>
                <Custom_select style={s.select_profile} name={text142}>
                    <TitleText editable={text130[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text130[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text131}>
                    <TitleText editable={text132[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text132[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text133}>
                    <TitleText editable={text134[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text134[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text135}>
                    <TitleText editable={text136[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text136[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text137}>
                    <TitleText editable={text138[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text138[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text139}>
                    <TitleText editable={text140[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text140[0]}
                    </TitleText>
                </Custom_select>
            </div>
            <TitleText editable={text141[1]} style={`${s.section_title}`} tag='div' weight='700' size='5' color='white'>
                {/* Гумінові кислоти */}
                {text141[0]}
            </TitleText>
            <div className={s.select__block}>
                <Custom_select style={s.select_profile} name={text143}>
                    <TitleText editable={text144[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text144[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text146}>
                    <TitleText editable={text147[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text147[0]}
                    </TitleText>
                </Custom_select>
            </div>
            <TitleText editable={text148[1]} style={`${s.section_title}`} tag='div' weight='700' size='5' color='white'>
                {/* Амінокислоти */}
                {text148[0]}
            </TitleText>
            <div className={s.select__block}>
                <Custom_select style={s.select_profile} name={text149}>
                    <TitleText editable={text150[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text150[0]}
                    </TitleText>
                </Custom_select>
                <Custom_select style={s.select_profile} name={text151}>
                    <TitleText editable={text152[1]} style={`${s.select_name}`} tag='div' weight='400' size='9' color='white'>
                        {text152[0]}
                    </TitleText>
                </Custom_select>
            </div>
        </section>
    )
}

export default Section_question;
