import { combineReducers } from "redux";
import edit from "./edit";
import loader from "./loader";
import path_page from "./path_page";
import text from "./text";
import posts from "./posts";
import meta from "./meta";

const rootReducer = combineReducers({
    edit,
    loader,
    path_page,
    text,
    posts,
    meta,
})

export default rootReducer;