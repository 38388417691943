// import './App.css';
import React from 'react';
import { Router } from './pages/route';
import { ScrollToTop } from './component';

function App() {
  return (
    <ScrollToTop>
      <Router />
    </ScrollToTop>
  );
}

export default App;
