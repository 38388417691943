import React from 'react';
import s from './style.module.scss';
import { Additional_navigate, TitleText } from '../index';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { publicRoutes } from '../../constans/routes';


const Start_section = React.memo(function Start_section(props) {
    const pathPage = useSelector(({ path_page }) => path_page.path);
    const route = publicRoutes;
    const [
        text56 = [], text57 = [],
        text58 = [], text59 = [],
        text60 = [], text61 = [], text62 = [],
    ] = useSelector(({ text }) => [
        text.json[56], text.json[57],
        text.json[58], text.json[59],
        text.json[60], text.json[61], text.json[62],
    ]);


    return (
        <>
            <section className={s.section}>
                <LazyLoad className={s.section_bg}>
                    <img src={props.bg} alt="background" />
                </LazyLoad>
                <Additional_navigate
                    pathPage={pathPage}
                    publicRoutes={route}
                    text56={text56}
                    text57={text57}
                    text58={text58}
                    text59={text59}
                    text60={text60}
                    text61={text61}
                    text62={text62}
                />
                <div className="container">
                    {typeof props.title == 'string' ? (
                        <TitleText style={s.section_title} tag='div' weight='700' size='3' color='white'>
                            {props.title}
                        </TitleText>
                    ) :
                        <TitleText editable={props.title[1]} style={s.section_title} tag='div' weight='700' size='3' color='white'>
                            {props.title[0]}
                        </TitleText>
                    }

                </div>
            </section>
        </>
    )
});

export default Start_section;
