import React, { useState } from 'react';
import s from './style.module.scss';

import Index from './Index';
import About from './About';
import Mycorrhiza from './Mycorrhiza';
import Gallery_page from './Gallery';
import Blog from './Blog';
import Post from './Post';
import { Corn, Crops, Vegetables, Beans, Garden, No_till, Agriculture } from './Categories/index';
import { useSelector, useDispatch } from "react-redux";

import { setPathPage } from "../redux/actions/setPathPage";
import { setText } from "../redux/actions/setText";
import { setPost } from "../redux/actions/setPost";
import { setMeta } from "../redux/actions/setMeta";
import { admin_check } from '../api/admin_check';

import { Header, Loader, Edit_panel } from './../component/index';
import { Switch, Route, useLocation } from 'react-router-dom';
import { publicRoutes } from '../constans/routes';

import { AnimatePresence } from 'framer-motion';
import { textRequest } from '../api/text/textRequest';
import { metaRequest } from '../api/text/meta';
import NotFound from './NotFound';
import { return_posts } from '../api/text/blog';
import MetaDecorator from '../component/MetaDecorator';

export const Router = React.memo(function Router(props) {
    const isLoad = useSelector(({ loader }) => loader.isLoad);
    const [all_text, Set_all_text] = React.useState();
    const [all_post, Set_all_post] = React.useState();
    const [admin, Set_admin] = React.useState();
    const [all_meta, Set_all_meta] = React.useState();
    const [curret_meta, Set_curret_meta] = React.useState();

    let location = useLocation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        admin_check(Set_admin);
        textRequest(Set_all_text);
        metaRequest(Set_all_meta);
        return_posts(Set_all_post);
    }, []);

    React.useEffect(() => {
        dispatch(setPathPage(location.pathname));
        if (all_meta != undefined) {
            set_path_page(all_meta);
        }
    }, [location.pathname]);


    React.useEffect(() => {
        dispatch(setText(all_text));
    }, [all_text]);

    React.useEffect(() => {
        dispatch(setPost(all_post));
    }, [all_post]);

    React.useEffect(() => {
        dispatch(setMeta(all_meta));
        if (all_meta != undefined) {
            set_path_page(all_meta);
        }

    }, [all_meta]);


    function set_path_page(array) {
        let path_name = location.pathname.split('/')[1];
        Set_curret_meta({
            title: '404',
            description: 'page 404'
        });
        array.map((item, index) => {
            if (item.page === 'main' && location.pathname === '/') {
                Set_curret_meta(item);
                return false;
            } else if (item.page == path_name) {
                Set_curret_meta(item);
                return false;
            }
        });
    }

    return (
        <>
            {isLoad &&
                <Loader />
            }
            <div className={`${s.main_container} main_container`}>
                {admin &&
                    <Edit_panel />
                }
                <Header />
                {curret_meta &&
                    <MetaDecorator description={curret_meta.description} title={curret_meta.title} />
                }
                <AnimatePresence>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path={publicRoutes.INDEX}>
                            <Index />
                        </Route>
                        <Route exact path={publicRoutes.ABOUT}>
                            <About />
                        </Route>
                        <Route exact path={publicRoutes.MYCORRHIZA}>
                            <Mycorrhiza />
                        </Route>
                        <Route exact path={publicRoutes.GALLERY}>
                            <Gallery_page />
                        </Route>
                        <Route exact path={publicRoutes.BLOG}>
                            <Blog />
                        </Route>
                        <Route exact path={`${publicRoutes.BLOG}/:id`}>
                            <Blog />
                        </Route>
                        <Route exact path={`${publicRoutes.POST}/:id`}>
                            <Post />
                        </Route>
                        <Route exact path={publicRoutes.CORN}>
                            <Corn />
                        </Route>
                        <Route exact path={publicRoutes.CROPS}>
                            <Crops />
                        </Route>
                        <Route exact path={publicRoutes.VEGETABLES}>
                            <Vegetables />
                        </Route>
                        <Route exact path={publicRoutes.BEANS}>
                            <Beans />
                        </Route>
                        <Route exact path={publicRoutes.GARDEN}>
                            <Garden />
                        </Route>
                        <Route exact path={publicRoutes.NOTILL}>
                            <No_till />
                        </Route>
                        <Route exact path={publicRoutes.AGRICULTURE}>
                            <Agriculture />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </AnimatePresence>
            </div>
        </>
    );
});
