import { fetch_text } from "../../api/text/fetch_text";
import s from './edit.module.scss';


export function addBtn(e, { loading, infoMassege }) {
    removeBtn();
    let item = e.target;
    item.classList.add('editable_active');
    createEditBtn(e, { loading, infoMassege });
}

export function removeBtn() {
    let elem = document.getElementsByClassName("update_text_btn__wrap")[0];
    let active_input = document.getElementsByClassName("editable_active")[0];

    if (active_input != undefined) {
        active_input.classList.remove("editable_active");
    }
    if (elem != undefined) {
        elem.parentNode.removeChild(elem);
    }
}

function createEditBtn(e, { loading, infoMassege }) {
    let item = e.target;
    // let container = item.closest('.container') || item.closest('section') || item.closest('.main_container');
    let text_id = item.dataset.id;
    let text = item.innerText;

    let update_text_btn__wrap = document.createElement('div');
    update_text_btn__wrap.classList.add('update_text_btn__wrap', s.update_text_btn__wrap);

    let update_text_viewer = document.createElement('div');
    update_text_viewer.classList.add('update_text_viewer', s.update_text_viewer);
    update_text_viewer.textContent = `Редагування: ${text.slice(0, 10) + (text.length > 10 ? "..." : "")}`;

    let update_text_btn = document.createElement('div');
    update_text_btn.classList.add('update_text_btn', s.content_access_btn);
    update_text_btn.textContent = `Зберегти`;
    update_text_btn.setAttribute(`data-id`, text_id);

    let close_text_btn = document.createElement('div');
    close_text_btn.classList.add(s.close_text_btn);
    close_text_btn.textContent = `Закрити`;

    close_text_btn.addEventListener('click', removeBtn);
    update_text_btn.addEventListener('click', (e) => {
        fetch_update_text(e, { loading, infoMassege });
    });

    update_text_btn__wrap.appendChild(close_text_btn);
    update_text_btn__wrap.appendChild(update_text_btn);
    update_text_btn__wrap.appendChild(update_text_viewer);
    document.body.appendChild(update_text_btn__wrap);
}

function fetch_update_text(e, { loading, infoMassege }) {
    let item = e.target;
    let text_id = item.dataset.id;
    let newText = document.getElementsByClassName('editable_active')[0].innerText;
    let values = {
        text_id: text_id,
        text: newText
    }
    fetch_text({ values, loading, infoMassege });
}