import React from 'react';
import s from './style.module.scss';
import blog_bg from '../../assets/blog/blog_bg.png';
import { Start_section, Post_card, Footer } from '../../component';
import { publicRoutes } from '../../constans/routes';
import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css";
import './pagination.scss';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../constans/animation";
import { useSelector } from 'react-redux';

const Blog = React.memo(function Blog(props) {
    const limit_post = 2;
    const [pageNum, setPageNum] = React.useState(1);
    const [pageMax, setPageMax] = React.useState(1);
    const [page_posts, setPage_posts] = React.useState([]);

    const [post_array, isLoad] = useSelector(({ posts }) => [posts.posts, posts.isLoad]);

    function handlePageClick(num) {
        console.log('num: ', num);
        setPageNum(num);
    }

    React.useEffect(() => {
        if (isLoad == true && post_array != undefined) {
            set_page_post();
            setPageMax(Math.ceil(post_array.length / limit_post));
        }
    }, [post_array])

    React.useEffect(() => {
        if (isLoad == true && post_array != undefined) {
            set_page_post();
        }
    }, [pageNum])


    function set_page_post() {
        let slice_index;
        let slice_limit;

        if (pageNum == 1) {
            slice_index = pageNum - 1;
            slice_limit = pageNum - 1 + limit_post;
        } else if (pageNum == 2) {
            slice_index = pageNum;
            slice_limit = pageNum + limit_post;
        } else if (pageNum > 2) {
            slice_index = pageNum + limit_post - 1;
            slice_limit = pageNum + limit_post + 1;
        }
        console.log('slice_index: ', slice_index);
        console.log('slice_limit: ', slice_limit);

        setPage_posts(post_array.slice(slice_index, slice_limit));
    }

    const [
        text232 = [], text39 = []
    ] = useSelector(({ text }) => [
        text.json[232], text.json[39],
    ]);



    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text232} bg={blog_bg} />
            <section className={`${s.section} container`}>
                {isLoad == true && post_array != undefined &&
                    page_posts.map((item, index) => {
                        let short_text = item.description.slice(0, 100) + (item.description.length > 100 ? "..." : "")

                        return (
                            <Post_card
                                key={index + item.id}
                                theme='black'
                                style={s.post_item}
                                img={item.post_image_url}
                                alt={item.name}
                                detail_btn={text39[0]}
                                title={item.name}
                                text={short_text}
                                link={`${publicRoutes.POST}/:${item.id}`}
                            />
                        )
                    })
                }

                <div className={s.pagination__block}>
                    {pageMax != 1 &&
                        <Pagination
                            className={s.pagination__block}
                            currentPage={pageNum}
                            totalPages={pageMax}
                            changeCurrentPage={handlePageClick}
                            theme="square-fill"
                        />
                    }
                </div>
            </section>
            <Footer />
        </ motion.div>
    )
});

export default Blog;
