import React from 'react';
import s from './style.module.scss';
import accept from '../../../../assets/index/accept.svg';
import { TitleText, Custom_option } from '../../../../component/index';



const Option__block = React.memo(function Option__block(props) {
    let all_options = [{
        id: props.text16[1],
        text: props.text16[0],
        svg: accept,
    },
    {
        id: props.text17[1],
        text: props.text17[0],
        svg: accept,
    },
    {
        id: props.text18[1],
        text: props.text18[0],
        svg: accept,
    },
    {
        id: props.text19[1],
        text: props.text19[0],
        svg: accept,
    },
    {
        id: props.text20[1],
        text: props.text20[0],
        svg: accept,
    },
    {
        id: props.text21[1],
        text: props.text21[0],
        svg: accept,
    },
    {
        id: props.text22[1],
        text: props.text22[0],
        svg: accept,
    },
    {
        id: props.text23[1],
        text: props.text23[0],
        svg: accept,
    },
    {
        id: props.text24[1],
        text: props.text24[0],
        svg: accept,
    },
    {
        id: props.text25[1],
        text: props.text25[0],
        svg: accept,
    },]

    return (
        <>
            <div className={s.preferens_option__block}>
                {all_options &&
                    all_options.map((item, index) => {
                        return (
                            <Custom_option style={s.preferens_option} key={index} svg={item.svg}>
                                <TitleText editable={item.id} tag='span' weight='400' size='10' color='white'>
                                    {item.text}
                                </TitleText>
                            </Custom_option>
                        )
                    })
                }

            </div>

        </>
    )
});

export default Option__block;
