import React from 'react';
import s from './style.module.scss';
import { TitleText, CustomBtn, CustomModal, Detail_btn, Order_btn } from '../../../../component/index';
import Product_cart from '../Product_cart/index';
import { useSelector } from 'react-redux';



const Product_content = React.memo(function Product_content(props) {
    const [
        text26 = [], text27 = [], text28 = [], text29 = [], text30 = [],
        text32 = [], text34 = [], text36 = [], text37 = [],
    ] = useSelector(({ text }) => [
        text.json[26], text.json[27], text.json[28], text.json[29], text.json[30],
        text.json[32], text.json[34], text.json[36], text.json[37],
    ]);


    return (
        <>
            <div className={s.product_content}>
                <TitleText editable={text26[1]} style={s.product_title} animation={true} tag='div' weight='700' size='3' color='green'>
                    {/* WellVam (ВеллВам) */}
                    {text26[0]}
                </TitleText>
                <TitleText editable={text27[1]} tag='div' weight='400' size='10' color='black'>
                    {/* Арбускулярно-візикулярна мікориза на гуміновому носію */}
                    {text27[0]}
                </TitleText>
                <TitleText editable={text28[1]} style={s.product_description} tag='div' weight='700' size='10' color='black'>
                    {/* Склад */}
                    {text28[0]}
                </TitleText>
                <TitleText editable={text29[1]} tag='div' weight='400' size='10' color='black'>
                    {/* Спори везикулярно-арбускулярної мікоризи 150 пропагул на 1 г продукту */}
                    {text29[0]}
                </TitleText>
                <div className={s.product_cart__block}>
                    <Product_cart procent={text30} text='Нітроген' />
                    <Product_cart procent={text32} text='Гумінові кислоти' />
                    <Product_cart procent={text34} text='Амінокилоти' />
                </div>
                <TitleText editable={text36[1]} style={s.product_description} tag='div' weight='700' size='10' color='black'>
                    {/* Виробник */}
                    {text36[0]}
                </TitleText>
                <TitleText editable={text37[1]} tag='div' weight='400' size='10' color='black'>
                    {/* Компанія "Wellcropbiotech", Індія */}
                    {text37[0]}
                </TitleText>
                <div className={s.product_btn__block}>
                    <Order_btn />
                    <Detail_btn />
                </div>

            </div>
        </>
    )
});

export default Product_content;
