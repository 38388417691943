import React from 'react';
import s from './style.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from '../../redux/actions/setLoader';
import { useSnackbar } from 'notistack';

// господи боже, просто не лізьте до цього файлика, хай собі працює 
import { addBtn } from './utils';



const TitleText = React.memo(function TitleText(props) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    let editable = props.editable;
    let edit = useSelector(({ edit }) => edit.edit);
    let size = `size_${props.size}`;
    let weight = `weight_${props.weight}`;
    let color = `color_${props.color}`;
    let classes = `${s[size]} ${s[weight]} ${s[color]} ${props.animation != undefined ? s.animation : ''} ${props.style != undefined ? props.style : ''}`;
    let text = props.children;
    let text_tag;


    function loading(status) {
        dispatch(setLoader(status));
    }

    function infoMassege([text, variant]) {
        enqueueSnackbar(text, { variant });
    }


    function stop_enter(event) {
        if (event.key === "Enter" && edit) {
            event.preventDefault();
        }
    }

    // if (props.animation != undefined) {
    //     function Hover_word(e) {
    //         let target = e.target;
    //         target.classList.add(s.active);

    //         setTimeout(() => {
    //             target.classList.remove(s.active);
    //         }, 1400);
    //     }
    //     text = text.replace(/\S/g, `$& `).split(' ').slice(0, -1);
    //     text = text.map((item, index) => {
    //         if (item == '') {
    //             return (
    //                 <span
    //                     key={`${index}_${item}`}>
    //                     &#160;
    //                 </span>
    //             )
    //         } else {
    //             return (
    //                 <span
    //                     onMouseEnter={(e) => Hover_word(e)}
    //                     key={`${index}_${item}`}>
    //                     {item}
    //                 </span>
    //             )
    //         }

    //     });
    // }

    function stop_events(e) {
        if (edit) {
            e.preventDefault();
            addBtn(e, { loading, infoMassege });
            document.addEventListener("keydown", stop_enter);
        } else {
            document.removeEventListener("keydown", stop_enter);
        }
    }



    if (editable != undefined) {
        text_tag = (
            <props.tag contentEditable={edit}
                suppressContentEditableWarning={edit}
                onClick={stop_events}
                data-id={editable}
                className={`${classes} ${edit ? s.admin_edit : ''} `}
                href={props.href}
            >
                {text}
            </props.tag >
        )
    } else {
        text_tag = (
            <props.tag
                className={classes}
                href={props.href}
            >
                {text}
            </props.tag >
        )
    }

    return text_tag;
});

export default TitleText;
