import React from 'react';
import s from './style.module.scss';
import TitleText from '../TitleText/index';
import { Link, useHistory } from 'react-router-dom';
import { additional_item } from '../../api/text/additional_item';
import Additional_nav_profile from './Additional_nav_profile';
import { Additional_nav_1, Additional_nav_2, Additional_nav_3, Additional_nav_4, Additional_nav_5, Additional_nav_6, Additional_nav_7 } from '../../assets/svg/Additional_navigate';


import SwiperCore, { A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';




SwiperCore.use([A11y, Autoplay]);

const Additional_navigate = React.memo(function Additional_navigate(props) {
    let additional_items = [{

        id: props.text56[1],
        name: props.text56[0],
        svg: Additional_nav_1,
        path: props.publicRoutes.CORN,
    },
    {
        id: props.text57[1],
        name: props.text57[0],
        svg: Additional_nav_2,
        path: props.publicRoutes.CROPS,
    },
    {
        id: props.text58[1],
        name: props.text58[0],
        svg: Additional_nav_3,
        path: props.publicRoutes.BEANS,
    },
    {
        id: props.text59[1],
        name: props.text59[0],
        svg: Additional_nav_4,
        path: props.publicRoutes.GARDEN,
    },
    {
        id: props.text60[1],
        name: props.text60[0],
        svg: Additional_nav_5,
        path: props.publicRoutes.VEGETABLES,
    },
    {
        id: props.text61[1],
        name: props.text61[0],
        svg: Additional_nav_6,
        path: props.publicRoutes.NOTILL,
    },
    {
        id: props.text62[1],
        name: props.text62[0],
        svg: Additional_nav_7,
        path: props.publicRoutes.AGRICULTURE,
    },];
    // let additional_json = additional_item();
    // const pathPage = useSelector(({ path_page }) => path_page.path);

    return (
        <>
            <div className={s.additional_nav__block}>
                {props.text62[1] != undefined &&
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={7}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                        }}
                        breakpoints={{
                            1360: {
                                slidesPerView: 7,
                            },
                            1100: {
                                slidesPerView: 5,
                            },

                            600: {
                                slidesPerView: 4,
                            },
                            430: {
                                slidesPerView: 3,
                            },
                            0: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {additional_items &&
                            additional_items.map((item, index) => {
                                return (
                                    <SwiperSlide key={`${index}_${item.name}`}><Additional_nav_profile path={item.path} history_location={props.pathPage} id={item.id} svg={item.svg} text={item.name} /></SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
        </>
    )
});

export default Additional_navigate;