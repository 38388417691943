
export const fetch_order = ({ api, values, loading, handleClose, infoMassege, resetForm }) => {
    if (handleClose !== undefined) {
        handleClose();
    }
    loading(true);
    const message_accept = ['Ваша заявка успішно відправлена, наші менеджери звяжуться з вами.', 'success'];
    const message_bad = ['Ой, трапилася помилка! Можливо ви ввели некоректні дані або спробуйте пізніше.', 'error'];

    // const dispatch = useDispatch();
    fetch(api, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            setTimeout(() => {
                let error = data.detail;

                if (error !== undefined) {
                    infoMassege(message_bad);
                } else {
                    infoMassege(message_accept);
                    resetForm({
                        errors: {},
                        touched: {}
                    });
                }
                loading(false);
            }, 1500);
        });
};