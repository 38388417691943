import React from 'react';
import s from './style.module.scss';
import preferens_bg from '../../../assets/index/preferens_bg.png';
import loop from '../../../assets/index/loop.png';
import accept from '../../../assets/index/accept.svg';
import { TitleText, Custom_option } from '../../../component/index';
import Option__block from './Option__block';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';



const Section_preferens = React.memo(function Section_preferens(props) {
    const [
        text15 = [], text16 = [], text17 = [], text18 = [],
        text19 = [], text20 = [], text21 = [], text22 = [],
        text23 = [], text24 = [], text25 = [],
    ] = useSelector(({ text }) => [
        text.json[15], text.json[16], text.json[17], text.json[18],
        text.json[19], text.json[20], text.json[21], text.json[22],
        text.json[23], text.json[24], text.json[25],
    ]);

    return (
        <>
            <section className={s.section_preferens}>
                <LazyLoad className={s.preferens_bg}>
                    <img src={preferens_bg} alt="preferens background" />
                </LazyLoad>
                <div className={`${s.preferens__block} container`}>
                    <div className={s.preferens_content}>
                        <div className={s.preferens_titles}>
                            <TitleText animation={true} tag='div' weight='700' size='2' color='white'>
                                Поживний
                            </TitleText>
                            <TitleText animation={true} tag='div' weight='700' size='2' color='green'>
                                Екологічний
                            </TitleText>
                            <TitleText animation={true} tag='div' weight='700' size='2' color='orange'>
                                Результативний
                            </TitleText>
                        </div>
                        <TitleText editable={text15[1]} tag='div' weight='700' size='5' color='white'>
                            {/* Мікориза - це симбіоз міцелію грибів із коренями рослин */}
                            {text15[0]}
                        </TitleText>
                        <Option__block
                            text16={text16}
                            text17={text17}
                            text18={text18}
                            text19={text19}
                            text20={text20}
                            text21={text21}
                            text22={text22}
                            text23={text23}
                            text24={text24}
                            text25={text25}
                        />
                    </div>
                    <LazyLoad className={s.preferens_img}>
                        <img src={loop} alt="preferens loop" />
                    </LazyLoad>
                </div>
            </section>
        </>
    )
});

export default Section_preferens;
