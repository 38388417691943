import React from 'react';
import s from './style.module.scss';
import { TitleText, CustomBtn, CustomModal } from '../index';
import { Formik, Field, Form } from 'formik';
import { CustomInput } from '../Form_element/index';
import * as yup from 'yup';
import setStaticHeight from '../../scripts/setStaticHeight';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/actions/setLoader';
import { useSnackbar } from 'notistack';
import { fetch_order } from '../../api/form/fetch_order';
import { publicAPI } from '../../constans/api';

const initialValues = {
    name: '',
    telephone: '',
    email: '',
    comment: '',
};

const validationSchema = yup.object({
    name: yup.string().required("Поле обов'язкове для заповнення"),
    telephone: yup.string().required("Поле обов'язкове для заповнення"),
    comment: yup.string().required("Поле обов'язкове для заповнення"),
    email: yup.string().email("Введіть справжній email").required("Поле обов'язкове для заповнення"),
});



const Consultation_btn = React.memo(function Consultation_btn(props) {
    const [open, setOpen] = React.useState(false);
    let api = publicAPI.CONSULTATION;

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    function loading(status) {
        dispatch(setLoader(status));
    }

    function infoMassege([text, variant]) {
        enqueueSnackbar(text, { variant });
    }

    const handleOpen = () => {
        setOpen(true);
        setStaticHeight(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStaticHeight(false);

    };

    return (
        <>
            <CustomBtn style={s.consultation_btn} tag='div' color='orange' href={handleOpen}>
                <TitleText editable={props.text46[1]} tag='span' weight='700' size='10' color='white'>
                    {/* Залишити запитання */}
                    {props.text46[0]}
                </TitleText>
            </CustomBtn>

            <CustomModal open={open} handleOpen={handleOpen} handleClose={handleClose}>
                <Formik
                    initialValues={initialValues}
                    validateOnBlur
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        fetch_order({ api, values, loading, handleClose, infoMassege, resetForm });
                        resetForm();
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                        <Form className={s.form}>
                            <TitleText style={s.order_text} tag='div' weight='500' size='7' color='orange'>
                                Залиште вашу заявку і наші менеджери звяжуться з вами
                            </TitleText>
                            <Field
                                placeholder="Ім'я"
                                name="name"
                                id="name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                component={CustomInput}
                            />
                            <Field
                                placeholder="Номер телефону"
                                name="telephone"
                                id="telephone"
                                mask="+380(99)999-99-99"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.telephone}
                                component={CustomInput}
                            />
                            <Field
                                placeholder="Email"
                                name="email"
                                id="email"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                component={CustomInput}
                            />
                            <Field
                                placeholder="Коментар"
                                name="comment"
                                id="comment"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comment}
                                component={CustomInput}
                            />
                            <CustomBtn tag='button' type={'submit'} color='orange' href={handleSubmit}>
                                <TitleText tag='span' weight='700' size='10' color='white'>
                                    Відправити
                                </TitleText>
                            </CustomBtn>
                        </Form>
                    )}

                </Formik>
            </CustomModal>
        </>
    );
});

export default Consultation_btn;
