
export const fetchRequest = ({ api, method, func }) => {

    fetch(api, {
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            func(data);
        })
        .catch((error) => {
            console.log('error: ', error);
        });
};