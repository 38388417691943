
export const admin_check = (func) => {
    fetch('/api/user-permision/', {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            let status = data[0].is_superuser === 'True' ? true : false;
            func(status);
        })
        .catch((error) => {
            console.log('error: ', error);
        });
};

