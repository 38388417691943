import React from 'react'
import { TitleText, Order_btn, Custom_option } from '../../../component'
import s from './style.module.scss';
import { useSelector } from 'react-redux';

function Section_info() {
    const [
        text157 = [], text158 = [], text159 = [],
        text160 = [], text161 = [], text162 = [],
        text163 = [], text164 = [], text165 = [],
        text166 = [], text167 = [], text168 = [],
        text169 = [], text170 = [], text171 = [],
        text172 = [], text173 = [], text174 = [],
        text175 = [], text176 = [], text177 = [],
        text178 = [],
    ] = useSelector(({ text }) => [
        text.json[157], text.json[158], text.json[159],
        text.json[160], text.json[161], text.json[162],
        text.json[163], text.json[164], text.json[165],
        text.json[166], text.json[167], text.json[168],
        text.json[169], text.json[170], text.json[171],
        text.json[172], text.json[173], text.json[174],
        text.json[175], text.json[176], text.json[177],
        text.json[178],
    ]);


    return (
        <div className={s.section_info}>
            <TitleText editable={text157[1]} style={`${s.section_info_title}`} tag='div' weight='700' size='6' color='black'>
                {/* Біопрепарат WellVam ®  (ВеллВАМ®), Індія */}
                {text157[0]}
            </TitleText>
            <Order_btn />
            <div className={s.section_info_item}>
                <TitleText editable={text159[1]} style={`${s.section_info_item_title}`} tag='span' weight='700' size='10' color='black'>
                    {text159[0]}
                </TitleText>
                <TitleText editable={text168[1]} style={`${s.section_info_item_title}`} tag='span' weight='400' size='10' color='black'>
                    {/* 2кг */}
                    {text168[0]}
                </TitleText>
            </div>
            <div className={s.section_info_item}>
                <TitleText editable={text160[1]} style={`${s.section_info_item_title}`} tag='span' weight='700' size='10' color='black'>
                    {/* Норма: */}
                    {text160[0]}
                </TitleText>
                <TitleText editable={text170[1]} style={`${s.section_info_item_title}`} tag='span' weight='400' size='10' color='black'>
                    {/* 4,0 – 4,95 кг на 1 га. Кількість добрива визначається біологічними особливостями с/г культур та умов вирощування. */}
                    {text170[0]}
                </TitleText>
            </div>
            <div className={s.section_info_item}>
                <TitleText editable={text161[1]} style={`${s.section_info_item_title}`} tag='div' weight='700' size='10' color='black'>
                    {/* Склад: */}
                    {text161[0]}
                </TitleText>
                <TitleText editable={text162[1]} style={`${s.section_info_item_title}`} tag='div' weight='400' size='10' color='black'>
                    {/* Препарат (гранульований) вироблений на основі мікоризного гриба містить наступні елементи: */}
                    {text162[0]}
                </TitleText>
                <Custom_option style={s.section_info_option} svg={'orange'}>
                    <TitleText editable={text163[1]} tag='span' weight='400' size='10' color='black'>
                        {/* живі вегетативні клітини, спори мікоризоутворюючого гриба – 150 спор/г, штамм Mycorriza; Glomus intraradices; */}
                        {text163[0]}
                    </TitleText>
                </Custom_option>
                <Custom_option style={s.section_info_option} svg={'orange'}>
                    <TitleText editable={text164[1]} tag='span' weight='400' size='10' color='black'>
                        {/* субстанція: 14-16% гумінової кислоти та 6-8 % амінокислоти. */}
                        {text164[0]}
                    </TitleText>
                </Custom_option>
            </div>
            <div className={s.section_info_item}>
                <TitleText editable={text165[1]} style={`${s.section_info_item_title}`} tag='div' weight='700' size='10' color='black'>
                    {/* Способи нанесення: */}
                    {text165[0]}
                </TitleText>
                <TitleText editable={text166[1]} style={`${s.section_info_item_title}`} tag='div' weight='400' size='10' color='black'>
                    {/* Рекомендовано як у сухому, так і в вигляді розчину, який готується в день використання. */}
                    {text166[0]}
                </TitleText>
                <Custom_option style={s.section_info_option} svg={'number'} number='1'>
                    <TitleText editable={text167[1]} tag='span' weight='400' size='10' color='black'>
                        {/* Внесення до ґрунту. Ретельно змішати препарат з ґрунтом або компостом. */}
                        {text167[0]}
                    </TitleText>
                </Custom_option>
                <Custom_option style={s.section_info_option} svg={'number'} number='2'>
                    <TitleText editable={text169[1]} tag='span' weight='400' size='10' color='black'>
                        {/* Внесення у сухому вигляді разом з насінням до сівалки. Ретельно перемішавши у ємності сівалки перед висівом. */}
                        {text169[0]}
                    </TitleText>
                </Custom_option>
                <Custom_option style={s.section_info_option} svg={'number'} number='3'>
                    <TitleText editable={text171[1]} tag='span' weight='400' size='10' color='black'>
                        {/* Обробка насіння перед посівом. Розчинити у достатній кількості води та обробити насіння. Оброблене насіння висіяти в день обробки. */}
                        {text171[0]}
                    </TitleText>
                </Custom_option>
                <Custom_option style={s.section_info_option} svg={'number'} number='4'>
                    <TitleText editable={text173[1]} tag='span' weight='400' size='10' color='black'>
                        {/* Внесення через полив або краплинне зрошення. Розчинити в достатній кількості води та внести до прикореневого ґрунту не раніше, чим на 10 день після отримання. */}
                        {text173[0]}
                    </TitleText>
                </Custom_option>
                <Custom_option style={s.section_info_option} svg={'number'} number='5'>
                    <TitleText editable={text175[1]} tag='span' weight='400' size='10' color='black'>
                        {/* Внесення розчину в борозну “in-furrow” в баковій суміші з мінеральними добривами. */}
                        {text175[0]}
                    </TitleText>
                </Custom_option>
                <div className={s.detail_description}>
                    <div className={s.detail_description_svg}>
                        <span>!</span>
                    </div>
                    <div className={s.detail_description_content}>
                        <TitleText editable={text177[1]} tag='div' weight='700' size='10' color='black'>
                            {/* Умови зберігання препарату */}
                            {text177[0]}
                        </TitleText>
                        <TitleText editable={text178[1]} tag='div' weight='400' size='10' color='black'>
                            {/* Зберігати та використовувати при температурі повітря не більше +35 °С, в сухому прохолодному місці. Запобігати заморожуванню продукту та прямих сонячних променів. */}
                            {text178[0]}
                        </TitleText>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Section_info
