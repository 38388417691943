// Анімашка переключення сторінок конфліктує з модалкою, ця функція фіксить цю проблему
export default function setStaticHeight(status) {
    let section_height = document.querySelector('.motion_section').clientHeight;
    let main_container = document.querySelector('.main_container');

    if (status) {
        main_container.style.height = section_height + 'px';
        document.getElementsByTagName('html')[0].style.overflowY = "hidden";
    } else {
        setTimeout(() => {
            main_container.style.height = '100%';
            document.getElementsByTagName('html')[0].style.overflowY = "scroll";
        }, 500);
    }
}