import React from 'react';
import s from './style.module.scss';
import about_bg from '../../assets/about/about.png';
import arrow from '../../assets/index/path-arrow.svg';
import shadow_1 from '../../assets/about/shadow_1.svg';
import shadow_2 from '../../assets/about/shadow_2.svg';
import { Start_section, TitleText, Custom_slider, Custom_option, Career_form, Footer } from '../../component';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../constans/animation";
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { return_photos } from '../../api/text/gallery';

let preferens_1 = require(`../../assets/about/pref_1.png`).default;
let preferens_2 = require(`../../assets/about/pref_2.png`).default;
let plus = require(`../../assets/about/plus.svg`).default;


const About = React.memo(function About(props) {
    const [photos_nature, Set_photos_nature] = React.useState();
    const [photos_people, Set_photos_people] = React.useState();
    let [active_first, setActive_first] = React.useState(false);
    let [active_second, setActive_second] = React.useState(false);
    let [active_third, setActive_third] = React.useState(false);

    function toggleClassFirst() {
        setActive_first(!active_first);
    }
    function toggleClassSecond() {
        setActive_second(!active_second);
    }
    function toggleClassThird() {
        setActive_third(!active_third);
    }

    React.useEffect(() => {
        if (photos_nature == undefined) {
            return_photos(Set_photos_nature, '/api/galary-nature/');
        }
        if (photos_people == undefined) {
            return_photos(Set_photos_people, '/api/galary-people/');
        }
    }, []);

    const [
        text1 = [], text65 = [], text66 = [], text67 = [],
        text68 = [], text69 = [], text70 = [], text71 = [],
        text72 = [], text73 = [], text74 = [], text75 = [],
        text76 = [], text77 = [], text78 = [], text79 = [],
        text80 = [], text81 = [], text82 = [], text83 = [],
    ] = useSelector(({ text }) => [
        text.json[1], text.json[65], text.json[66], text.json[67],
        text.json[68], text.json[69], text.json[70], text.json[71],
        text.json[72], text.json[73], text.json[74], text.json[75],
        text.json[76], text.json[77], text.json[78], text.json[79],
        text.json[80], text.json[81], text.json[82], text.json[83],
    ]);
    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <div className={s.main_about_section}>
                <Start_section title={text1} bg={about_bg} />
                <section className={`${s.preferens}`}>
                    <div className={s.preferens_profile}>
                        {/* <TitleText editable={text74[1]} animation={true} style={`${s.preferens_shadow}`} tag='div' weight='700' size='shadow' color='white'>
                            {text74[0]}
                        </TitleText> */}
                        <img src={shadow_1} className={s.preferens_shadow} alt="" />

                        <LazyLoad className={s.preferens_img}>
                            <img src={preferens_1} alt="preferens_1" />
                        </LazyLoad>
                        <div className={s.preferens_content}>
                            <TitleText editable={text74[1]} style={`${s.preferens_title}`} tag='div' weight='700' size='3' color='white'>
                                {/* Місія */}
                                {text74[0]}
                            </TitleText>

                            <Custom_option style={s.preferens_option} svg={plus}>
                                <TitleText editable={text75[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* У прагненні до інновацій у Eco-Friendly сільському господарстві, що базується на традиційних та сучасних технологіях. */}
                                    {text75[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.preferens_option} svg={plus}>
                                <TitleText editable={text76[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* У відновленні ґрунтів, шляхом заміни небезпечних хімічних речовин корисними мікроорганізмами */}
                                    {text76[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.preferens_option} svg={plus}>
                                <TitleText editable={text77[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* У задоволенні своїх клієнтів, повагу до наших клієнтів, прислухаємось до їх запитів та розуміємо їх очікування. */}
                                    {text77[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                    </div>
                    <div className={s.preferens_profile}>
                        {/* <TitleText editable={text78[1]} animation={true} style={`${s.preferens_shadow}`} tag='div' weight='700' size='shadow' color='white'>
                            {text78[0]}
                        </TitleText> */}
                        <img src={shadow_2} className={s.preferens_shadow} alt="" />
                        <LazyLoad className={s.preferens_img}>
                            <img src={preferens_2} alt="preferens_2" />
                        </LazyLoad>
                        <div className={s.preferens_content}>
                            <TitleText editable={text78[1]} style={`${s.preferens_title}`} tag='div' weight='700' size='3' color='white'>
                                {/* Бачення */}
                                {text78[0]}
                            </TitleText>
                            <Custom_option style={s.preferens_option} svg={plus}>
                                <TitleText editable={text79[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Ми пропонуємо новий шлях -  шлях заміни небезпечних хімічних речовин у сільському господарстві на інноваційні екологічні біоорганічні рішення. */}
                                    {text79[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.preferens_option} svg={plus}>
                                <TitleText editable={text80[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Ми готові зміцнити агровиробництво через зв'язок з природою  і прагнемо стати щасливою та здоровою частиною сільського господарства України. */}
                                    {text80[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                    </div>
                </section>

                <section className={`${s.section}`}>
                    <div className={s.section__block}>
                        <TitleText editable={text65[1]} style={`${s.section_text} ${s.section_text_1}`} tag='div' weight='400' size='9' color='black'>
                            {/* За кожним проектом чи ідеєю стоять люди чи людина. Ідея нашого проекту зародилась у нашій сім’ї. Це, мабуть, досить оригінально в наш час відверто повідомити вам про мотивацію, про події та знайомства, які спонукали нас та стали рушійною силою у просуванні біопрепаратів виробництва індійської компанії «WELLCROPBIOTECH» в Україні. Нумо! */}
                            {text65[0]}
                        </TitleText>
                        <div className={`${s.section_paragraph} ${active_first ? s.active : ''}`}>
                            <div onClick={toggleClassFirst} className={s.section_head}>
                                <TitleText editable={text66[1]} style={`${s.section_text}`} tag='div' weight='700' size='5' color='black'>
                                    {/* Про зв’язок з природою */}
                                    {text66[0]}
                                </TitleText>
                                <img src={arrow} className={s.section_arrow} alt="arrow" />
                            </div>
                            <div className={s.section_paragraph_content}>
                                <TitleText editable={text67[1]} style={`${s.section_text} ${s.section_date}`} tag='div' weight='700' size='10' color='black'>
                                    {/* 2014 р. США, штат Колорадо. */}
                                    {text67[0]}
                                </TitleText>
                                <TitleText editable={text68[1]} style={`${s.section_text}`} tag='div' weight='400' size='9' color='black'>
                                    {/* Персиковий сад на березі р. Колорадо. Сад зрошується водою з цієї ж річки. Вода жорстка залишає крейдяний і трохи мінеральний присмак і, як з’ясувалось, не тільки смак. Листя молодих саджанців персика, які були висаджені пару років тому почали жовтіти, ріст явно загальмувався. Мінеральне живлення не давало ефекту, а навпаки. Проаналізували усі фактори – виявилось, що причина у воді, точніше у її мінералізації. Перше що зробили, це на насосній станції встановили обладнання для внесення сумішей, які б пом’якшували воду. Але це вже для майбутнього зрошування. А сад тим часом гине! Як і для нашого фермера, гине джерело існування усієї родини! Тим чудом, що спасло цей сад стає мікоризний препарат. Вже через декілька днів після внесення мікоризи у крапельний полив, прожилки на листві зазеленіли і сад почав відновлюватись на очах. Все це спостерігала Бучко Юлія, яка вигравши грант і за програмою Cohran Folloship від Посольства США проходила тренінг за темою «Conservation and Irrigation Technology» у штаті Колорадо. */}
                                    {text68[0]}
                                </TitleText>
                                {photos_nature != undefined && photos_nature.length != 0 &&
                                    <Custom_slider slider='first' slides={photos_nature} />
                                }
                            </div>
                        </div>
                        <div className={`${s.section_paragraph} ${active_second ? s.active : ''}`}>
                            <div onClick={toggleClassSecond} className={s.section_head}>
                                <TitleText editable={text69[1]} style={`${s.section_text}`} tag='div' weight='700' size='5' color='black'>
                                    {/* Про зв’язок з людьми */}
                                    {text69[0]}
                                </TitleText>
                                <img src={arrow} className={s.section_arrow} alt="arrow" />
                            </div>

                            <div className={s.section_paragraph_content}>
                                <TitleText editable={text70[1]} style={`${s.section_text} ${s.section_date}`} tag='div' weight='700' size='10' color='black'>
                                    {/* 2016 р. Китай, Харбін. */}
                                    {text70[0]}
                                </TitleText>
                                <TitleText editable={text71[1]} style={`${s.section_text}`} tag='div' weight='400' size='9' color='black'>
                                    {/* Ця історія не про емігрантів з Росії, не про КВЗД, хоча зв’язок існує, і навіть не про льодові скульптури Харбіну. Наступний член нашої родини - Бучко Тетяна, зазіхнувши на святе – на бюджетні кошти Піднебесної виграла грант від Китайської народної республіки на здобуття ступеня магістра у Харбінському університеті. Друзі в університеті походили з різних куточків світу, але особливо цікаво було спілкуватись з вихідцями з азійських країн.
                                Культура Східної Азії зазвичай вражає європейців, в тому числі і культура харчування. Студенти з Індії вживали лише їжу рослинного походження, заварювали чаї на молоці і це все було незвично приємне, різноманітне, а головне смачне. Індуси вживали лише органічні продукти, що відповідало не тільки їх релігійним переконанням, а й тому, що це був їх звичайний стиль життя.
                                Так знайомство з культурою Індії надихнуло звернути увагу на біопрепарати, які вони застосовують для органічного вирощування. Треба розуміти, що Індія стоїть на першому місці в світі по кількості операторів ринку органічної продукції. Спрацювало правило декількох рукостискань і ось вже є нові знайомі, які працюють на сучасному заводі біопрепаратів з лінійкою продукції, яка забезпечує повний захист рослин і про цьому абсолютно безпечна. */}
                                    {text71[0]}
                                </TitleText>
                                {photos_people != undefined && photos_people.length != 0 &&
                                    <Custom_slider slider='second' slides={photos_people} />
                                }
                            </div>
                        </div>
                        <div className={`${s.section_paragraph} ${active_third ? s.active : ''}`}>
                            <div onClick={toggleClassThird} className={s.section_head}>
                                <TitleText editable={text72[1]} style={`${s.section_text}`} tag='div' weight='700' size='5' color='black'>
                                    {/* Про нас з вами */}
                                    {text72[0]}
                                </TitleText>
                                <img src={arrow} className={s.section_arrow} alt="arrow" />
                            </div>

                            <div className={s.section_paragraph_content}>
                                <TitleText editable={text73[1]} style={`${s.section_text}`} tag='div' weight='400' size='9' color='black'>
                                    {/* У вересні 2019 року нарешті змогли отримати Свідоцтво про державну реєстрацію першого препарату «ВеллВАМ». А ви звернули увагу, що назва вже гарантує вам добро?
                                Ми не встигали поставити препарат на посівну озимих культур, тому всі плани продажу та тестувань перенесено на весну 2020 року. А тут вже розповідь про нас з вами. */}
                                    {text73[0]}
                                </TitleText>
                            </div>
                        </div>
                    </div>
                </section>


                <Career_form
                    text81={text81}
                    text82={text82}
                    text83={text83}
                />
            </div>
            <Footer />
        </ motion.div>
    )
});

export default About;
