import React from 'react';
import s from './style.module.scss';
import { TitleText, CustomBtn, CustomModal } from '../index';
import { Formik, Field, Form } from 'formik';
import { CustomInput } from '../Form_element/index';
import * as yup from 'yup';
import setStaticHeight from '../../scripts/setStaticHeight';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/actions/setLoader';
import { useSnackbar } from 'notistack';
import { fetch_order } from '../../api/form/fetch_order';
import { publicAPI } from '../../constans/api';


const Contact_btn = React.memo(function Contact_btn(props) {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
        setStaticHeight(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStaticHeight(false);
    };

    return (
        <>
            <CustomBtn style={s.consultation_btn} tag='div' color='orange' href={handleOpen}>
                <TitleText editable={props.text145[1]} tag='span' weight='700' size='10' color='white'>
                    {props.text145[0]}
                </TitleText>
            </CustomBtn>

            <CustomModal open={open} handleOpen={handleOpen} handleClose={handleClose}>
                <TitleText style={s.contact_title} tag='span' weight='700' size='2' color='orange'>
                    Наші контакти
                </TitleText>
                <div className={s.contact__block}>
                    <TitleText tag='span' weight='700' size='5' color='black'>
                        Адреса
                    </TitleText>
                    <a href="https://goo.gl/maps/kVJtzLw591B32GTX9" target='_blank'>
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            Адреса: 75100  Херсонська обл. м. Олешки, вул. Торгова, 1
                        </TitleText>
                    </a>
                </div>
                <div className={s.contact__block}>
                    <TitleText tag='span' weight='700' size='5' color='black'>
                        Телефони
                    </TitleText>
                    <a href="tel:+380972416037">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            +380972416037
                        </TitleText>
                    </a>
                    <a href="tel:+380506451316">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            +380506451316
                        </TitleText>
                    </a>
                </div>
                <div className={s.contact__block}>
                    <TitleText tag='span' weight='700' size='5' color='black'>
                        Почта
                    </TitleText>
                    <a href="mailto:t.buchko@agrogrow.com.ua">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            t.buchko@agrogrow.com.ua
                        </TitleText>
                    </a>
                    <a href="mailto:y.buchko@agrogrow.com.ua">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            y.buchko@agrogrow.com.ua
                        </TitleText>
                    </a>
                    <a href="mailto:accountant@agrogrow.com.ua">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            accountant@agrogrow.com.ua
                        </TitleText>
                    </a>
                    <a href="mailto:sales@agrogrow.com.ua">
                        <TitleText tag='span' weight='500' size='10' color='black'>
                            sales@agrogrow.com.ua
                        </TitleText>
                    </a>
                </div>


            </CustomModal>
        </>
    );
});

export default Contact_btn;
