const initialState = {
    json: {}
};

const text = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TEXT':
            if (action.text != undefined) {
                let json = {};
                action.text.map((item, index) => {
                    json[item.text_type] = [item.text, item.text_type];
                });
                return {
                    ...state,
                    json
                }
            }

        default:
            return state;
    }
}


export default text;