import React from 'react';
import s from '../style.module.scss';
import vegetables_bg from '../../../assets/categories/vegetables_bg.png';

import vegetables_table_1 from '../../../assets/categories/vegetables_table_1.svg';
import vegetables_table_2 from '../../../assets/categories/vegetables_table_2.svg';
import { Start_section, TitleText, Custom_slider, Custom_option, Order_btn, Question_section, Footer } from '../../../component/index';
import Section_info from '../Section_info';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../../constans/animation";
import { useSelector } from 'react-redux';
import { return_photos } from '../../../api/text/gallery';

const Vegetables = React.memo(function Vegetables(props) {
    const [photos_vegetables, Set_photos_vegetables] = React.useState();

    React.useEffect(() => {
        if (photos_vegetables == undefined) {
            return_photos(Set_photos_vegetables, '/api/galary-vegetables/');
        }
    }, []);


    const [
        text185 = [], text186 = [], text187 = [],
        text188 = [], text231 = []
    ] = useSelector(({ text }) => [
        text.json[185], text.json[186], text.json[187],
        text.json[188], text.json[231]
    ]);

    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text185} bg={vegetables_bg} />
            <div className={`${s.section} container`}>
                <div className={s.section_content__block}>
                    <div className={s.section_content}>
                        <TitleText editable={text186[1]} style={`${s.section_content_title}`} tag='div' weight='700' size='5' color='black'>
                            {/* Результати */}
                            {text186[0]}
                        </TitleText>
                        <TitleText editable={text187[1]} style={`${s.section_content_subtitle}`} tag='div' weight='700' size='6' color='black'>
                            {/* Перець. с. В. Копані, Олешки, Херсонська */}
                            {text187[0]}
                        </TitleText>
                        <div className={s.section_content_img}>
                            <img src={vegetables_table_1} alt="vegetables_table" />
                        </div>
                        <TitleText editable={text188[1]} style={`${s.section_content_subtitle}`} tag='div' weight='700' size='6' color='black'>
                            {/* Перець. с. В. Копані, Олешки, Херсонська */}
                            {/* 2. Контроль хвороб тканин рослин */}
                            {text188[0]}
                        </TitleText>
                        <div className={s.section_content_img}>
                            <img src={vegetables_table_2} alt="vegetables_table" />
                        </div>
                    </div>
                </div>
                <Section_info />
            </div>
            <div className={`${s.section_slider} container`}>
                <TitleText editable={text231[1]} style={`${s.section_slider_title}`} tag='div' weight='700' size='5' color='black'>
                    {/* Галерея */}
                    {text231[0]}
                </TitleText>
                {photos_vegetables != undefined && photos_vegetables.length != 0 &&
                    <Custom_slider slider='first' slides={photos_vegetables} />
                }
            </div>
            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default Vegetables;
