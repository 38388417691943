const initialState = {
    meta: []
}
const meta = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_META':
            return {
                ...state,
                meta: action.meta
            }

        default:
            return state;
    }
}


export default meta;