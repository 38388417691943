import React from 'react';
import s from './style.module.scss';
import blog_bg_1 from '../../../assets/index/blog_bg_1.png';
import blog_bg_2 from '../../../assets/index/blog_bg_2.png';
import test_img from '../../../assets/index/index_bg_1.png';
import { TitleText, Post_card, CustomBtn } from '../../../component/index';
import { publicRoutes } from '../../../constans/routes';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';



const Section_blog = React.memo(function Section_blog(props) {
    const [post_array, isLoad] = useSelector(({ posts }) => [posts.posts, posts.isLoad]);

    React.useEffect(() => {
        if (isLoad == true && post_array != undefined) {
            console.log('post_array: ', post_array);
        }
    }, [post_array])




    const [
        text43 = [], text39 = [],
    ] = useSelector(({ text }) => [
        text.json[43], text.json[39],
    ]);
    function func() {
    };


    return (
        <>
            <section className={`${s.section_blog} container`}>
                <LazyLoad>
                    <img src={blog_bg_1} className={s.blog_bg_1} alt="background" />
                </LazyLoad>
                <LazyLoad>
                    <img src={blog_bg_2} className={s.blog_bg_2} alt="background" />
                </LazyLoad>
                <TitleText animation={true} style={s.section_title} tag='div' weight='700' size='3' color='white'>
                    Публікації
                </TitleText>
                <div className={s.post__block}>
                    {isLoad == true && post_array != undefined &&
                        post_array.slice(0, 2).map((item, index) => {
                            let short_text = item.description.slice(0, 100) + (item.description.length > 100 ? "..." : "")
                            return (
                                <Post_card
                                    key={index + item.id}
                                    theme='white'
                                    style={s.post_item}
                                    img={item.post_image_url}
                                    alt={item.name}
                                    detail_btn={text39[0]}
                                    title={item.name}
                                    text={short_text}
                                    link={`${publicRoutes.POST}/:${item.id}`}
                                />
                            )
                        })
                    }
                </div>
                <Link to={publicRoutes.BLOG} className={s.blog_btn} >
                    <CustomBtn tag='div' color='transparent' href={func}>
                        <TitleText editable={text43[1]} tag='span' weight='700' size='10' color='black'>
                            {/* УСІ СТАТТІ */}
                            {text43[0]}
                        </TitleText>
                    </CustomBtn>
                </Link>

            </section>
        </>
    )
});

export default Section_blog;
