import React from 'react';
import s from './style.module.scss';
import about_bg from '../../assets/about/about.png';
import { Start_section, TitleText, Custom_slider, Custom_option, Career_form, Footer } from '../../component';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../constans/animation";


const NotFound = React.memo(function NotFound(props) {
    return (
        <motion.div
            className={`motion_section ${s.section__wrapper}`}
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={'Такої сторінки не існує'} bg={about_bg} />
            <section className={`${s.section} container`}>
                <TitleText style={`${s.section_text} ${s.section_text_1}`} tag='span' weight='700' size='shadow' color='red'>
                    404
                </TitleText>
            </section>
            <Footer />
        </ motion.div>
    )
});

export default NotFound;
