
// Знаходить висоту і ширину фото
function getRealSizeImg(img) {
    let width;
    let height;
    // img.onload = function () {
    if (img.tagName !== 'IMG')
        return;
    if (img.naturalWidth) {
        width = img.naturalWidth;
        height = img.naturalHeight;
    } else {
        let helpImg = new Image();
        helpImg.src = img.src;

        width = helpImg.width;
        height = helpImg.height;
    }

    return {
        width: width,
        height: height,
    }
    // }
}


// повертає масив з розмірами і силкою на фото, 
// якщо повільний інтернет, то вертає фіксоване відношення сторін
export default function generate_gallery(photos, Set_gallery) {
    let photos_array = [];

    photos.map((item, index) => {
        let helpImg = new Image();
        helpImg.src = item.image_url;
        helpImg.classList.add('get_params');
        helpImg.dataset.imageUrl = item.image_url;
        document.body.appendChild(helpImg);
    });

    setTimeout(() => {
        let all_img = document.querySelectorAll(".get_params");
        [].forEach.call(all_img, function (item) {
            let param = getRealSizeImg(item);
            let src_item = item.dataset.imageUrl;
            photos_array.push({
                src: src_item,
                width: param.width == 0 ? 4 : param.width,
                height: param.height == 0 ? 3 : param.height,
            });
            item.remove();
        });

        Set_gallery(photos_array);
        // dispatch(setLoader(false));
    }, 1000);
}