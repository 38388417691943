import React from 'react';
import s from './style.module.scss';
import { TitleText, CustomBtn, CustomModal } from '../index';
import { Formik, Field, Form } from 'formik';
import { CustomInput } from '../Form_element/index';
import * as yup from 'yup';
import career_bg from '../../assets/about/about.png';
import LazyLoad from 'react-lazyload';
import { fetch_order } from '../../api/form/fetch_order';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/actions/setLoader';
import { useSnackbar } from 'notistack';
import { publicAPI } from '../../constans/api';

const initialValues = {
    name: '',
    telephone: '',
    email: '',
};

const validationSchema = yup.object({
    name: yup.string().required("Поле обов'язкове для заповнення"),
    telephone: yup.string().required("Поле обов'язкове для заповнення"),
    email: yup.string().email("Введіть справжній email").required("Поле обов'язкове для заповнення"),
});


const Career_form = React.memo(function Career_form(props) {
    let api = publicAPI.CAREER;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    function loading(status) {
        dispatch(setLoader(status));
    }

    function infoMassege([text, variant]) {
        enqueueSnackbar(text, { variant });
    }

    return (
        <>
            <section className={`${s.section} container`}>
                <LazyLoad className={s.section_bg}>
                    <img src={career_bg} alt="background" />
                </LazyLoad>
                <Formik
                    initialValues={initialValues}
                    validateOnBlur
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm, setErrors, errors }) => {
                        fetch_order({ api, values, loading, infoMassege, resetForm });
                        resetForm();

                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => {
                        console.log('touched: ', touched);
                        console.log('errors: ', errors);
                        return (

                            <Form className={s.form}>
                                <TitleText editable={props.text81[1]} animation={true} style={s.form_title} tag='div' weight='700' size='3' color='white'>
                                    {/* Кар’єра */}
                                    {props.text81[0]}
                                </TitleText>
                                <TitleText editable={props.text82[1]} style={s.form_text} tag='div' weight='700' size='5' color='white'>
                                    {/* Напишіть нам листа, зателефонуйте, а нумо на каву разом! */}
                                    {props.text82[0]}
                                </TitleText>
                                <TitleText editable={props.text83[1]} style={s.form_text} tag='div' weight='400' size='9' color='white'>
                                    {/* Створюємо команду однодумців, які відкриті для інновацій, нестандартних рішень і готові свої мрії впроваджувати в життя. Разом рухатись набагато легше і головне – цікавіше. */}
                                    {props.text83[0]}
                                </TitleText>
                                <div className={s.form__block}>
                                    <Field
                                        placeholder="Ім'я"
                                        name="name"
                                        id="name"
                                        type="text"
                                        styles={s.form_item}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        component={CustomInput}
                                    />
                                    <Field
                                        placeholder="Номер телефону"
                                        name="telephone"
                                        id="telephone"
                                        mask="+380(99)999-99-99"
                                        type="tel"
                                        styles={s.form_item}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.telephone}
                                        component={CustomInput}
                                    />
                                    <Field
                                        placeholder="Email"
                                        name="email"
                                        id="email"
                                        type="text"
                                        styles={s.form_item}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        component={CustomInput}
                                    />
                                    <CustomBtn style={s.form_item} tag='button' type={'submit'} color='green' href={handleSubmit}>
                                        <TitleText tag='span' weight='700' size='10' color='white'>
                                            Відправити
                                </TitleText>
                                    </CustomBtn>
                                </div>
                            </Form>
                        )
                    }}

                </Formik>
            </section>
        </>
    );
});

export default Career_form;
