import React from 'react'
import s from './style.module.scss';
import TitleText from '../TitleText';
import { useDispatch } from 'react-redux';
import { setEdit } from '../../redux/actions/setEdit';
import { removeBtn } from '../../component/TitleText/utils';
import { useLocation } from 'react-router-dom';

const Edit_panel = React.memo(function Edit_panel() {
    const dispatch = useDispatch();
    let location = useLocation();

    const [active, Set_active] = React.useState(false);
    const [show_interface, Set_show_interface] = React.useState(false);
    const refSpan = React.useRef();

    React.useEffect(() => {
        dispatch(setEdit(active));
    }, [])

    React.useEffect(() => {
        removeBtn();
    }, [location.pathname]);

    React.useEffect(() => {
        dispatch(setEdit(active));
        removeBtn();
    }, [active])

    function toggleShow() {
        Set_show_interface(!show_interface);
    }

    function toggleClick() {
        Set_active(!active);

        if (active) {
            refSpan.current.innerHTML = 'OFF';
        } else {
            refSpan.current.innerHTML = 'ON';
        }
    }


    return (
        <>
            <div className={`${s.section} ${show_interface ? s.show : ''}`}>
                <div className={s.edit__block}>
                    <span className={s.edit_title}>
                        Панель редагування
                </span>

                    <div onClick={toggleClick} className={`${s.switch} ${active ? s.active : ''}`}>
                        <div ref={refSpan} className={s.span}>OFF</div>
                    </div>
                </div>

                <a href="/admin/" target="_blank" className={s.section_btn} target='_blank'>
                    <TitleText tag='span' weight='500' size='10' color='orange'>
                        Відкрити сторінку адміністрування
                    </TitleText>
                </a>

            </div>
            <div className={`${s.toggle} ${s.section_show_btn} ${show_interface ? s.active : ''}`}>
                <input type="checkbox" id={s.toggle} readOnly />
                <label onClick={toggleShow} readOnly htmlFor={s.toggle}></label>
            </div>

        </>
    )
});

export default Edit_panel
