import React from 'react';
import s from './style.module.scss';
import mycorrhiza_bg from '../../assets/mycorrhiza/mycorrhiza.png';
import { Start_section, TitleText, Footer } from '../../component';
import Section_preferens from './Section_preferens/index';
import Section_question from './Section_question';
import { motion } from 'framer-motion';
import { pageTransition } from "../../constans/animation";
import { useSelector } from 'react-redux';


const Mycorrhiza = React.memo(function Mycorrhiza(props) {
    const [
        text84 = [], text86 = [], text87 = [],
        text88 = [], text89 = [], text90 = [],
        text91 = [], text92 = [], text93 = [],
    ] = useSelector(({ text }) => [
        text.json[84], text.json[86], text.json[87],
        text.json[88], text.json[89], text.json[90],
        text.json[91], text.json[92], text.json[93],
    ]);


    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >

            {/* Веллвам */}
            <Start_section title={text84} bg={mycorrhiza_bg} />
            <Section_preferens
                text86={text86}
                text87={text87}
                text88={text88}
                text89={text89}
                text90={text90}
                text91={text91}
                text92={text92}
                text93={text93}
            />
            <Section_question />
            <Footer />
        </motion.div>
    )
});

export default Mycorrhiza;
