import React from 'react';
import s from './style.module.scss';
import first_bg from '../../../assets/index/index_bg_1.png';
import { Additional_navigate, TitleText } from '../../../component/index';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { publicRoutes } from '../../../constans/routes';

const Section_main = React.memo(function Section_main(props) {
    const pathPage = useSelector(({ path_page }) => path_page.path);
    const route = publicRoutes;

    const [
        text11 = [], text12 = [], text13 = [],
        text56 = [], text57 = [],
        text58 = [], text59 = [],
        text60 = [], text61 = [], text62 = [],
    ] = useSelector(({ text }) => [
        text.json[11], text.json[12], text.json[13],
        text.json[56], text.json[57],
        text.json[58], text.json[59],
        text.json[60], text.json[61], text.json[62],
    ]);

    return (
        <>
            <section className={s.section_main}>
                <LazyLoad className={s.index_bg}>
                    <img src={first_bg} alt="background" />
                </LazyLoad>

                <Additional_navigate
                    pathPage={pathPage}
                    publicRoutes={route}
                    text56={text56}
                    text57={text57}
                    text58={text58}
                    text59={text59}
                    text60={text60}
                    text61={text61}
                    text62={text62}
                />
                <div className={`${s.main_content__block} container`}>
                    <div className={s.main_content}>
                        <TitleText editable={text11[1]} animation={true} tag='span' weight='700' size='3' color='white'>
                            {/* AGROGROWDEVELOP */}
                            {text11[0]}
                        </TitleText>
                        <TitleText editable={text12[1]} animation={true} tag='span' weight='400' size='4' color='orange'>
                            {/* зв'язок з природою */}
                            {text12[0]}
                        </TitleText>
                    </div>
                    <div className={s.main_content}>
                        <TitleText editable={text13[1]} tag='span' weight='400' size='7' color='white'>
                            {/* Біологічні препарати для вирощування сільгосппродукції */}
                            {text13[0]}
                        </TitleText>
                    </div>
                </div>
            </section>
        </>
    )
});

export default Section_main;
