import React from 'react';
import s from '../style.module.scss';
import agriculture_bg from '../../../assets/categories/organic_bg.jpg';
import organic_1 from '../../../assets/categories/organic_1.jpg';
import organic_2 from '../../../assets/categories/organic_2.jpg';
import organic_3 from '../../../assets/categories/organic_3.jpg';
import plus from '../../../assets/categories/plus.svg';
import { Start_section, TitleText, Custom_slider, Custom_option, Order_btn, Question_section, Footer } from '../../../component/index';
import Section_info from '../Section_info';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../../constans/animation";
import { useSelector } from 'react-redux';

const Agriculture = React.memo(function Agriculture(props) {

    const [
        text211 = [], text212 = [], text213 = [], text214 = [],
        text215 = [], text216 = [], text217 = [],
        text218 = [], text219 = [], text220 = [],
        text221 = [], text222 = [], text223 = [],
        text224 = [], text225 = [], text226 = [],
        text227 = [], text228 = [], text229 = [],
    ] = useSelector(({ text }) => [
        text.json[211], text.json[212], text.json[213], text.json[214],
        text.json[215], text.json[216], text.json[217],
        text.json[218], text.json[219], text.json[220],
        text.json[221], text.json[222], text.json[223],
        text.json[224], text.json[225], text.json[226],
        text.json[227], text.json[228], text.json[229],
    ]);

    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text211} bg={agriculture_bg} />
            <div className={`${s.section} container`}>
                <div className={s.section_content__block}>
                    <div className={s.section_content}>
                        <TitleText editable={text212[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Використання біопрепарату «ВеллВАМ» є 100% природним та стійким не лише для довкілля, але й для прибутковості фермерів. Практична цінність біологічного препарату «ВеллВАМ» полягає у безпеці для людини, теплокровних тварин і він не забруднює навколишнє природне середовище. Застосування препарату в органічному землеробстві надає певні переваги: */}
                            {text212[0]}
                        </TitleText>
                        <div className={s.section_content_profile}>
                            <img className={s.section_content_profile_bg} src={organic_1} alt="agriculture_bg" />
                            <TitleText editable={text213[1]} style={`${s.section_content_profile_title}`} tag='div' weight='700' size='5' color='white'>
                                {/* Для рослин */}
                                {text213[0]}
                            </TitleText>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text214[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Підвищується опір листковим хворобам */}
                                    {text214[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text215[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Пом’якшується вплив абіотичних стресів */}
                                    {text215[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text216[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Покращується постачання поживних речовин */}
                                    {text216[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text217[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Підвищується опір до кореневих патогенів */}
                                    {text217[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text218[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Збільшується засвоєння фосфору */}
                                    {text218[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                        <div className={s.section_content_profile}>
                            <img className={s.section_content_profile_bg} src={organic_2} alt="agriculture_bg" />
                            <TitleText editable={text219[1]} style={`${s.section_content_profile_title}`} tag='div' weight='700' size='5' color='white'>
                                {/* Для грунтів */}
                                {text219[0]}
                            </TitleText>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text220[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Поновлення ґрунтової біоти */}
                                    {text220[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text221[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Поліпшення інфільтрації води та збільшення ємності її у ґрунті */}
                                    {text221[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text222[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Посилення опору щодо ущільнення ґрунту та утворенню корки */}
                                    {text222[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text223[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Покращення проникнення повітря */}
                                    {text223[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                        <div className={s.section_content_profile}>
                            <img className={s.section_content_profile_bg} src={organic_3} alt="agriculture_bg" />
                            <TitleText editable={text224[1]} style={`${s.section_content_profile_title}`} tag='div' weight='700' size='5' color='white'>
                                {/* Для фермера */}
                                {text224[0]}
                            </TitleText>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text225[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Підвищення урожайності від 15 до 45 %, та аж на 300 % (цитрусові, виноград, ананас) */}
                                    {text225[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text226[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Зниження навантаження мінеральними добривами */}
                                    {text226[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text227[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Збільшення відсотку виживання рослин від сходів до збирання */}
                                    {text227[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text228[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Вирощування якісної органічної продукції безпечної для людини */}
                                    {text228[0]}
                                </TitleText>
                            </Custom_option>
                            <Custom_option style={s.section_content_option} svg={plus}>
                                <TitleText editable={text229[1]} tag='span' weight='400' size='9' color='white'>
                                    {/* Додаткові прибутки від реалізації органічної продукції */}
                                    {text229[0]}
                                </TitleText>
                            </Custom_option>
                        </div>
                    </div>
                </div>
                <Section_info />
            </div>

            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default Agriculture;
