
const initialState = {
    posts: [],
    isLoad: false
}
const posts = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_POST':
            return {
                ...state,
                posts: action.data,
                isLoad: true,
            }

        default:
            return state;
    }
}


export default posts;