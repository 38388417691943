import React from 'react';
import s from './style.module.scss';
import { publicRoutes } from '../../../constans/routes';
import { TitleText } from '../../index';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Footer_logo } from '../../../assets/svg/Logo';



function Header_nav(props) {
    const pathPage = useSelector(({ path_page }) => path_page.path);
    const [text1 = [], text2 = [], text3 = [], text54 = [], text52 = [], text158 = []] = useSelector(({ text }) => [text.json[1], text.json[2], text.json[3], text.json[54], text.json[52], text.json[158]]);

    return (
        <div className={`${s.header_nav__block} ${props.active_menu ? s.active : ''}`}>
            <nav className={`${s.header_nav}`}>
                <div className={s.mobile_logo}>
                    <Footer_logo />
                </div>
                <Link to={publicRoutes.INDEX} onClick={() => props.func()} className={`${s.header_nav_link} ${publicRoutes.INDEX == pathPage ? s.active : ''}`} >
                    <TitleText editable={text158[1]} tag='span' weight='700' size='9' color='white'>
                        {text158[0]}
                    </TitleText>
                </Link>
                <Link to={publicRoutes.ABOUT} onClick={() => props.func()} className={`${s.header_nav_link} ${publicRoutes.ABOUT == pathPage ? s.active : ''}`} >
                    <TitleText editable={text1[1]} tag='span' weight='700' size='9' color='white'>
                        {/* Про нас */}
                        {text1[0]}
                    </TitleText>
                </Link>
                <Link to={publicRoutes.MYCORRHIZA} onClick={() => props.func()} className={`${s.header_nav_link} ${publicRoutes.MYCORRHIZA == pathPage ? s.active : ''}`} >
                    <TitleText editable={text2[1]} tag='span' weight='700' size='9' color='white'>
                        {/* Мікориза ВеллВам */}
                        {text2[0]}
                    </TitleText>
                </Link>

                <Link to={publicRoutes.GALLERY} onClick={() => props.func()} className={`${s.header_nav_link} ${publicRoutes.GALLERY == pathPage ? s.active : ''}`} >
                    <TitleText editable={text3[1]} tag='span' weight='700' size='9' color='white'>
                        {/* Галерея */}
                        {text3[0]}
                    </TitleText>
                </Link>
                <Link to={publicRoutes.BLOG} onClick={() => props.func()} className={`${s.header_nav_link} ${publicRoutes.BLOG == pathPage ? s.active : ''}`} >
                    <TitleText editable={text52[1]} tag='span' weight='700' size='9' color='white'>
                        {/* Пости */}
                        {text52[0]}
                    </TitleText>
                </Link>

                <a className={s.header_nav_link} href="https://tviilis.org/" target="_blank">
                    <TitleText editable={text54[1]} tag='span' weight='700' size='9' color='white'>
                        {/* Проєкт ГО ТВІЙ ЛІС */}
                        {text54[0]}
                    </TitleText>
                </a>
                <div className={s.mobile_tel}>
                    <TitleText tag='a' href='tel:380972416037' weight='400' size='10' color='white'>
                        +3 80 97 241 60 37
                    </TitleText>
                </div>
            </nav>
        </div>
    )
};

export default Header_nav;
