import { fetchRequest } from "../../scripts/fetchRequest";

export const preferens_items = [{
    alt: 'preferens img',
    src: require(`../../assets/mycorrhiza/pref_1.svg`).default,
    title: 'Унікальний склад',
    text: 'Крім візикулярно-арбускулярних грибів препарат включає гумінові та амінокислоти'
},
{
    alt: 'preferens img',
    src: require(`../../assets/mycorrhiza/pref_2.svg`).default,
    title: 'Препаративна форма-гранули',
    text: 'Завдяки гранулам - препарат відмінно зберігається при температурі від + 4 до +35°С'
},
{
    alt: 'preferens img',
    src: require(`../../assets/mycorrhiza/pref_3.svg`).default,
    title: 'Розчинність 98%',
    text: 'Дозволяє використовувати при крапельному зрошенні та для поливу у розчину.'
},
{
    alt: 'preferens img',
    src: require(`../../assets/mycorrhiza/pref_4.svg`).default,
    title: 'Сумісність з добривами',
    text: 'Внесення розчину препарату у баковій суміші з мінеральними добривами надає препарату технологічності і підвищує його ефективність.'
},];


export const usage_items = [
    'Азоксистробін',
    'Піраклостробін',
    'Дифеноконазол',
    'Пропамокарб',
    'Іпконазол',
    'Протіоконазол',
    'Іпродіон',
    'Тебуконазол',
    'Карбоксин (пригнічує у вис. дозах)',
    'Тирам (пригнічує у високих дозах)',
    'Манкоцеб (може пригнічувати)',
    'Флудиоксоніл',
    'Металаксил',
    'Фосетил алюмінію',
    'Міклобутаніл',
    'Ципроконазол',
];

export const unusage_items = [
    'Беноміл',
    'Каптан',
    'Мідь',
    'Пропіконазол',
    'Триадіменол',
    'Триадімефон',
    'Фолпет',
];


export const qustion_items = {
    'first': [{
        name: 'Що таке мікориза?',
        text: `Мікоризу –це ґрунтові гриби здатні утворювати симбіоз з корінням вищих рослин. За допомогою ниткоподібних гіфів гриби обплітають коріння рослин, в деяких випадках проникаючи навіть глибоко всередину кореневої системи, утворюючи при цьому єдину біосистему, грібокорінь, відомий як мікориза.
        Навіть при високому вмісті гумусу в ґрунті рослини можуть відчувати брак поживних речовин через відсутність у них спеціальних ферментів, здатних перетворити складні біохімічні сполуки в легкодоступні для кореневого живлення мінеральні форми. В процесі своєї життєдіяльності рослини через коріння виділяють в ґрунт багаті вуглеводами з'єднання, в т. Ч. Різні цукру, які є цінним поживним речовиною для деяких видів ґрунтових грибних організмів.`,
    },
    {
        name: 'Які різновиди мікоризних грибів бувають? ',
        text: `Мікоризні гриби мають два види:
        «Ектомікориза» - це представник, що здатний активно поширитися по зовнішній стороні всієї кореневої системи, утворюючи своєрідний кокон, що в науковому світі називають «трубками». Гіфи мають можливість швидко і масово поширитися, але, не проникаючи в саму клітину. Такий вид мікоризи можна зустріти виключно поблизу сосни або берези. При наявності ектомікоризних грибів коренева система рослин не має волосків.
        «Ендомікориза» - це представники мікоризи, яким властиво повне «вростання» в кореневу систему рослин, саме тому споглядати без мікроскопів цей вид грибів, неможливо. Перевагою цього виду вважається легкий контакт з будь-якими рослинами, тому бажаний симбіоз з кореневою системою можуть бути з усіма трав'яними рослинами і навіть різноманітними деревами. До цього виду грибів належить везикулярна-арбускулярна  мікориза.
        `,
    },
    {
        name: 'З якими рослинами мікоризні гриби утворюють симбіоз, а з якими ні? ',
        text: `Мікоризи формують симбіоз з 90% видів рослин на планеті, зокрема, арбускулярна мікориза — з 80% видами. Мікоризні гриби не утворюють симбіотичних відносин з рослинами сімейства хрестоцвіті - хрін, редис, капуста, дайкон. Також не формують мікоризу культури сімейства амарантові - буряк, амарант, цукрові буряки, а також Гречані - ревінь, гречка.`
    },
    {
        name: 'Як впливають мікоризні грибі на рослини?',
        text: `Подібне співіснування взаємовигідно і рослині, і грибу, дозволяючи кожному організму отримувати саме ті продукти харчування, які їм необхідні, в найбільш доступній формі. Мікориза дозволяє значно розширити зону харчування рослини, так як грибні гіфи поширюються на значній площі і здатні проникати на велику глибину, ніж коріння. В результаті в 15 разів збільшується кількість поглинаються корінням корисних речовин (мінерали, вітаміни, ферменти, гормони, біоактивні сполуки та ін.), І відзначається більш інтенсивне зростання культур. Займаючи прикореневу зону, грибний міцелій утворює досить щільну структуру, захищаючи підземну частину рослини від патогенних мікроорганізмів і перешкоджаючи вимиванню корисних елементів (фосфор, калій та ін.) з ґрунту. Завдяки мікоризі коренева система поглинає велику кількість води, і рослини менше страждають від посухи.`
    },
    {
        name: 'Яка користь від мікоризи ґрунту?',
        text: `Мікориза робить позитивний вплив на структурування, збагачення, рекультивацію ґрунтів шляхом стабілізації процесів гуміфікації та мінералізації, що сприяє підвищенню родючості ґрунту. Поновлення ґрунтової біоти поліпшує інфільтрації води та збільшення ємності її зберігання у ґрунті. Також посилюється опір щодо ущільнення ґрунту та утворення ґрунтової корки і як результат - покращення проникності повітря.`
    },
    {
        name: 'Як довго живе мікориза? ',
        text: `Мікориза жива, поки живе рослина. У багаторічних культур довгі роки разом з «господарем», у однорічних рослин термін життя мікоризи - вегетаційний сезон.`
    },],
    'second': [
        {
            name: 'Чим корисні гумінові кислоти для рослини?',
            text: `Гумінові кислоти - чудовий природний та органічний спосіб забезпечити рослини та ґрунт концентрованою дозою необхідних поживних речовин, вітамінів та мікроелементів. Порівняно з іншими органічними продуктами, гумати покращують ріст рослин (виробництво біомаси) та родючість ґрунту. Ще однією перевагою гуматів є його довгострокова ефективність, оскільки він не споживається так швидко, як тваринний гній, компост або торф.`
        },],
    'third': [{
        name: 'Яку роль відіграють амінокислоти у розвитку рослин?',
        text: `Амінокислоти відіграють важливу роль у життєдіяльності рослин як стимулюючий компонент і є готовим запасом речовин, необхідних для перебігу біологічних процесів. Тобто вони є не тільки складовими білків, а й беруть безпосередню участь у регуляції росту та розвитку рослин, виступають попередниками в процесі утворення ряду ключових регуляторів обміну речовин. Амінокислоти також беруть участь у синтезі не лише білків, а й ферментів, нуклеїнових кислот, складних вуглеводів, жирів, фітогормонів і низки інших необхідних для рослинного організму сполук. Не викликає сумніву й той факт, що амінокислоти здатні підтримувати нормальне функціонування органів і систем у разі виникнення екстремального стану зовнішнього середовища – абіотичних стресів.`
    },
    ]
}


export const return_pdf_files = (func) => {
    fetchRequest({
        api: '/api/download/',
        method: 'GET',
        func
    });
}