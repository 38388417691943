import React from 'react';
import s from './style.module.scss';
import blog_bg from '../../assets/blog/blog_bg.png';
import { Start_section, TitleText, Footer } from '../../component';
import { Link, useLocation } from 'react-router-dom';
import { publicRoutes } from '../../constans/routes';
import { motion } from 'framer-motion';
import { pageTransition } from "../../constans/animation";
import { useSelector } from 'react-redux';

const Post = React.memo(function Post(props) {
    const [post_array, isLoad] = useSelector(({ posts }) => [posts.posts, posts.isLoad]);

    const [currentPost, setCurrentPost] = React.useState([]);
    let location = useLocation();
    let current_post = Number(location.pathname.split('/:')[1]);
    React.useEffect(() => {
        if (isLoad == true && post_array != undefined) {
            post_array.map((item, index) => {
                if (item.id == current_post) {
                    setCurrentPost(item);
                    return false;
                }
            })
        }
    }, [post_array])

    const [
        text232 = []
    ] = useSelector(({ text }) => [
        text.json[232]
    ]);




    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text232} bg={blog_bg} />
            <section className={`${s.section} container`}>
                {currentPost &&
                    <>
                        <div className={s.section_img}>
                            <img src={`../${currentPost.post_image_url}`} alt={currentPost.name} />
                        </div>
                        <TitleText style={s.section_title} tag='div' weight='700' size='4' color='black'>
                            {currentPost.name}
                        </TitleText>
                        <TitleText style={s.section_text} tag='div' weight='400' size='9' color='black'>
                            {currentPost.description}
                        </TitleText>
                    </>
                }

            </section>
            <section className={`${s.popular_post_section} container`}>
                <TitleText style={s.popular_post_title} tag='div' weight='700' size='5' color='black'>
                    Рекомендовані статті:
                </TitleText>
                <div className={s.popular_post__block}>
                    {post_array && post_array != undefined &&
                        post_array.slice(0, 3).map((item, index) => {
                            console.log('index: ', index);
                            return (
                                <Link to={`${publicRoutes.POST}/:${item.id}`} className={s.popular_post} key={index} >
                                    <img className={s.popular_post_bg} src={`../${item.post_image_url}`} alt={item.name} />
                                    <TitleText style={s.popular_post_text} tag='span' weight='700' size='9' color='white'>
                                        {item.name}
                                    </TitleText>
                                </Link>
                            )

                        })
                    }

                </div>
            </section>
            <Footer />
        </ motion.div>
    )
});

export default Post;
