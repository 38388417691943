const initialState = {
    path: '/'
}
const path_page = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PATH_PAGE':
            return {
                ...state,
                path: action.path
            }

        default:
            return state;
    }
}


export default path_page;