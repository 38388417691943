import React from 'react';
import s from '../style.module.scss';
import corn_bg from '../../../assets/categories/corn_bg.png';
import corn_table from '../../../assets/categories/corn_table.svg';
import { Start_section, TitleText, Custom_slider, Custom_option, Order_btn, Question_section, Footer } from '../../../component/index';
import Section_info from '../Section_info';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../../constans/animation";
import { useSelector } from 'react-redux';
import { return_photos } from '../../../api/text/gallery';

const Corn = React.memo(function Corn(props) {
    const [photos_corn, Set_photos_corn] = React.useState();

    React.useEffect(() => {
        if (photos_corn == undefined) {
            return_photos(Set_photos_corn, '/api/galary-corn/');
        }
    }, []);

    const [
        text56 = [], text154 = [], text155 = [],
        text156 = [], text231 = []
    ] = useSelector(({ text }) => [
        text.json[56], text.json[154], text.json[155],
        text.json[156], text.json[231],
    ]);




    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text56} bg={corn_bg} />
            <div className={`${s.section} container`}>
                <div className={s.section_content__block}>
                    <div className={s.section_content}>
                        <TitleText editable={text154[1]} style={`${s.section_content_title}`} tag='div' weight='700' size='5' color='black'>
                            {/* Результати */}
                            {text154[0]}
                        </TitleText>
                        <TitleText editable={text155[1]} style={`${s.section_content_subtitle}`} tag='div' weight='700' size='6' color='black'>
                            {/* Кукурудза ТОВ “Дружба Нова” с. Світличне, Чернігівська обл. */}
                            {text155[0]}
                        </TitleText>
                        <TitleText editable={text156[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='10' color='black'>
                            {/* прибавка 5,44 -6,02 ц/га */}
                            {text156[0]}
                        </TitleText>
                        <div className={s.section_content_img}>
                            <img src={corn_table} alt="corn_table" />
                        </div>
                    </div>
                </div>
                <Section_info />
            </div>
            <div className={`${s.section_slider} container`}>
                <TitleText editable={text231[1]} style={`${s.section_slider_title}`} tag='div' weight='700' size='5' color='black'>
                    {/* Галерея */}
                    {text231[0]}
                </TitleText>
                {photos_corn != undefined && photos_corn.length != 0 &&
                    <Custom_slider slider='first' slides={photos_corn} />
                }
            </div>
            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default Corn;
