import React from 'react';

export const Sociate_fb = React.memo(function Sociate_fb(props) {
    return (
        <a href={props.href} target='_blank' className={props.style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                <g fill="none" fillRule="evenodd">
                    <g fill="#FFF" fillRule="nonzero">
                        <g>
                            <g>
                                <path d="M14.875 0C16.047 0 17 .953 17 2.125v12.75C17 16.047 16.047 17 14.875 17H2.125C.953 17 0 16.047 0 14.875V2.125C0 .953.953 0 2.125 0zm-1.063 3.188h-2.124C9.927 3.188 8.5 4.614 8.5 6.374V8.5H6.375v2.656H8.5V17h3.188v-5.844h1.593L14.344 8.5h-2.656V6.375c0-.547.414-.536.946-.532h1.178V3.189z" transform="translate(-1368 -10) translate(14 10) translate(1354)" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </a>
    );
});
export const Sociate_tube = React.memo(function Sociate_tube(props) {
    return (
        <a href={props.href} target='_blank' className={props.style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18">
                <g fill="none" fillRule="evenodd">
                    <g fill="#FFF" fillRule="nonzero">
                        <g>
                            <g>
                                <path d="M13.209 0c3.081.006 6.426.05 7.816.144 1.498.084 2.234.306 2.913 1.512C24.643 2.861 25 4.936 25 8.591v.012c0 3.638-.356 5.728-1.06 6.92-.68 1.207-1.415 1.425-2.913 1.527-1.178.068-3.754.114-6.385.13l-.719.004-1.42.003h-.352c-.471 0-.95-.002-1.43-.004l-.718-.005c-2.508-.019-4.907-.064-6.03-.13-1.495-.101-2.23-.32-2.915-1.526C.359 14.33 0 12.239 0 8.602v-.013c0-3.653.36-5.728 1.058-6.933C1.742.448 2.48.228 3.975.142 5.364.05 8.71.006 11.796.001zM9.375 3.907v9.375l7.813-4.687-7.813-4.688z" transform="translate(-1401 -10) translate(14 10) translate(1387)" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </a>
    );
});

export const Sociate_inst = React.memo(function Sociate_inst(props) {
    return (
        <a href={props.href} target='_blank' className={props.style}>
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-instagram fa-w-14 fa-2x"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" className=""></path></svg>
        </a>
    );
});

export const Edit_svg = React.memo(function Edit_svg(props) {
    return (
        <a onClick={props.func} target='_blank' className={props.style}>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-edit fa-w-18 fa-2x"><path fill="currentColor" d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" class=""></path></svg>
        </a>
    );
});


