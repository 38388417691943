import React from 'react';
import s from '../style.module.scss';
import no_till_bg from '../../../assets/categories/notill_bg.jpg';
import plus from '../../../assets/categories/plus.svg';
import { Start_section, TitleText, Custom_slider, Custom_option, Order_btn, Question_section, Footer } from '../../../component/index';
import Section_info from '../Section_info';
import { motion, } from 'framer-motion';
import { pageTransition } from "../../../constans/animation";
import { useSelector } from 'react-redux';
import { return_photos } from '../../../api/text/gallery';

const No_till = React.memo(function No_till(props) {
    const [photos_notill, Set_photos_notill] = React.useState();

    React.useEffect(() => {
        if (photos_notill == undefined) {
            return_photos(Set_photos_notill, '/api/galary-notill/');
        }
    }, []);

    const [
        text194 = [], text195 = [], text196 = [],
        text198 = [], text199 = [], text200 = [],
        text201 = [], text202 = [], text203 = [],
        text204 = [], text205 = [], text206 = [],
        text207 = [], text208 = [], text209 = [],
        text210 = [], text231 = [], text197 = [],
    ] = useSelector(({ text }) => [
        text.json[194], text.json[195], text.json[196],
        text.json[198], text.json[199], text.json[200],
        text.json[201], text.json[202], text.json[203],
        text.json[204], text.json[205], text.json[206],
        text.json[207], text.json[208], text.json[209],
        text.json[210], text.json[231], text.json[197],
    ]);


    return (
        <motion.div
            className='motion_section'
            style={pageTransition.style}
            initial='initial'
            animate='in'
            exit='out'
            variants={pageTransition}
            transition={pageTransition.transition}
        >
            <Start_section title={text194} bg={no_till_bg} />
            <div className={`${s.section} container`}>
                <div className={s.section_content__block}>
                    <div className={s.section_content}>
                        <TitleText editable={text195[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Серед основних заходів, спрямованих на зниження негативного впливу посухи що посилюється через глобальне потепління, є впровадження і поширення грунтозберігаючих технологій. Сьогодні в багатьох країнах світу популяризують і впроваджують у виробництво нові, хоч вони не такі вже й нові, технології землеробства: No-Till, Strip-Till, Mini-Till. */}
                            {text195[0]}
                        </TitleText>
                        <TitleText editable={text196[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Ці системи землеробства в першу чергу пов'язані із забезпеченням екологічних функцій ґрунтів. На які функції ґрунтів впливає застосування мікоризного препарату? */}
                            {text196[0]}
                        </TitleText>
                        <TitleText editable={text197[1]} style={`${s.section_content_description}`} tag='div' weight='700' size='9' color='black'>
                            {/* Переваги біопрепарату «ВеллВАМ» у грунтозберігаючих технологіях: */}
                            {text197[0]}
                        </TitleText>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text198[1]} tag='span' weight='400' size='9' color='black'>
                                {/* накопичення вологи */}
                                {text198[0]}
                            </TitleText>
                        </Custom_option>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text199[1]} tag='span' weight='400' size='9' color='black'>
                                {/* регулюванням процесів влагообміну */}
                                {text199[0]}
                            </TitleText>
                        </Custom_option>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text200[1]} tag='span' weight='400' size='9' color='black'>
                                {/* регулювання газообміну та теплообміну */}
                                {text200[0]}
                            </TitleText>
                        </Custom_option>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text201[1]} tag='span' weight='400' size='9' color='black'>
                                {/* відновлення ґрунтової мікрофлори */}
                                {text201[0]}
                            </TitleText>
                        </Custom_option>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text202[1]} tag='span' weight='400' size='9' color='black'>
                                {/* технологічність застосування */}
                                {text202[0]}
                            </TitleText>
                        </Custom_option>
                        <Custom_option style={s.section_content_option} direction='center' svg={plus}>
                            <TitleText editable={text203[1]} tag='span' weight='400' size='9' color='black'>
                                {/* економічна доцільність */}
                                {text203[0]}
                            </TitleText>
                        </Custom_option>
                        <br />
                        <TitleText editable={text204[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* У міру прогресування вегетаційного періоду ви можете не помічати багато того, що відбувається з рослинами під ґрунтом. Більшість з нас звертають увагу на нові пагони, стебла, листя, а з часом на квіти та врожай. Ми можемо вважати коріння необхідними, але не такими вже й цікавими частинами у процесі вирощування. */}
                            {text204[0]}
                        </TitleText>
                        <TitleText editable={text205[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Але ми маємо зосередитись на тому, що відбувається в ґрунті з корінням рослин. Зона ґрунту, що оточує коріння рослини, називається ризосферою. І це жваве місце для важливих, але прихованих процесів у рослинництві */}
                            {text205[0]}
                        </TitleText>
                        <TitleText editable={text206[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* У ризосфері рослини утворюють різноманітні хімічні сполуки, які називаються ексудатами. Вплив ексудатів на рослини та навколишнє ґрунтове співтовариство – дуже важливий. Їх унікальна робота проводить дрібномасштабні дійства поблизу поверхні коренів. Властивості ґрунту у зоні впливу ексудатів можуть сильно відрізнятися від решти ґрунту. */}
                            {text206[0]}
                        </TitleText>
                        <TitleText editable={text207[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Коріння постійно виділяють хімічні речовини в ґрунт як спосіб вивільнення поживних речовин, які прикріплюються до частинок ґрунту через мережу мікоризних грибів. Під час травлення у людини шлунок виділяє шлункові соки, що сприяють розщепленню їжі; ексудати - це рослинний еквівалент шлункових соків. */}
                            {text207[0]}
                        </TitleText>
                        <TitleText editable={text208[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Хімічний склад ексудатів як "справжній коктейль" або "шведський стіл" для будь-чого в ризосфері. Окрім того, що вони допомагають рослинам отримувати поживні речовини, ексудати є джерелом їжі для мікроорганізмів, частиною яких і є везикулярно - арбускулярна мікориза, як важлива частина ґрунтової мікро біоти */}
                            {text208[0]}
                        </TitleText>
                        <TitleText editable={text209[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Мікоризні гриби також відіграють важливу роль в утриманні ґрунту. Ексудати та мікоризні гриби, через свою розгалужену мережу, утримують разом більші грудочки ґрунту, тут ексудати працюють на мікрорівні як клей, вони утримують частинки ґрунту у важливих механічних мережах. */}
                            {text209[0]}
                        </TitleText>
                        <TitleText editable={text210[1]} style={`${s.section_content_description}`} tag='div' weight='400' size='9' color='black'>
                            {/* Впродовж вегетаційного періоду - і в подальшому - між рослинами та навколишнім ґрунтом формуються делікатні взаємодії. Всі ці взаємодії впливають на кількість води, яка захоплюється ґрунтом і поглинається рослинами. Симбіоз коренів та мікоризних грибів також впливає на те, наскільки рослини можуть витягувати життєвоважливі поживні речовини з ґрунту, і навіть впливає на структуру ґрунту і ступень поглинання вологи у ризосфері. */}
                            {text210[0]}
                        </TitleText>
                    </div>
                </div>
                <Section_info />
            </div>
            <div className={`${s.section_slider} container`}>
                <TitleText editable={text231[1]} style={`${s.section_slider_title}`} tag='div' weight='700' size='5' color='black'>
                    {/* Галерея */}
                    {text231[0]}
                </TitleText>
                {photos_notill != undefined && photos_notill.length != 0 &&
                    <Custom_slider slider='first' slides={photos_notill} />
                }
            </div>
            <Question_section />
            <Footer />
        </ motion.div>
    )
});

export default No_till;
