const initialState = {
    edit: false
}
const edit = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_EDIT':
            return {
                ...state,
                edit: action.edit
            }

        default:
            return state;
    }
}


export default edit;