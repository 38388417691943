export const pageTransition = {
    initial: {
        opacity: 1,
        x: '-100vw',
    },
    in: {
        opacity: 1,
        x: 0,
    },
    out: {
        opacity: 0,
        x: '100vw',
    },
    transition: {
        type: 'tween',
        ease: 'anticipate',
        duration: 1
    },
    style: {
        position: 'absolute',
        maxWidth: '100%'
    }

}