const initialState = {
    isLoad: false
}
const loader = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADER':
            return {
                ...state,
                isLoad: action.isLoad
            }

        default:
            return state;
    }
}


export default loader;