import React from 'react';
import ReactDOM from 'react-dom';
import styles from './assets/normalize/index.scss';

import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack';
import store from "./redux/store";
import App from './App';



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
